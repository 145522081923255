import React, { FunctionComponent } from 'react';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { dark, light } from '../../theme/theme';

export interface AppEmptyLayoutProps {
  children: JSX.Element | React.ReactNode;
}

export const AppEmptyLayout: FunctionComponent<AppEmptyLayoutProps> = (
  props
) => {
  /* eslint-disable-next-line */
  const currentTheme = createMuiTheme(dark);

  return <ThemeProvider theme={currentTheme}>{props.children}</ThemeProvider>;
};

export default AppEmptyLayout;

import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ArrowRight as ArrowRightIcon } from '@material-ui/icons';

export interface LinkProps {
  to: string;
  target?: HTMLAnchorElement['target'];
  children?: React.ReactNode;
}
const ExternalLink = ({ to, target = '_blank', children }: LinkProps) => (
  <a href={to} target={target}>
    {children}
  </a>
);

export interface PageButtonProps {
  label: string | number;
  url: string;
  external?: boolean;
}
export const PageButton: FunctionComponent<PageButtonProps> = (props) => {
  const link = props.url ? props.url : '/';
  const RootLink: FunctionComponent<LinkProps> = props.external
    ? ExternalLink
    : RouterLink;

  return (
    <RootLink to={link}>
      <div>
        <Button
          color="secondary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="outlined"
        >
          <span style={{ marginTop: '.15rem' }}>{props.label}</span>
        </Button>
      </div>
    </RootLink>
  );
};

export default PageButton;

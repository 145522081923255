import Keycloak from 'keycloak-js';
import { environment } from '@cisco-unified-cloud/shared/client-env';

export const keycloak =
  typeof window !== 'undefined' &&
  new (Keycloak as any)({
    realm: environment.NX_KEYCLOAK_REALM,
    url: environment.NX_KEYCLOAK_BASE_URL,
    clientId: environment.NX_KEYCLOAK_CLIENT_ID,
  });

import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export interface CostTrendChartProps {
  data?: {
    month: string;
    amount: number;
    color: string;
  }[];
}

const staticData = [
  {
    month: 'JAN',
    amount: 1150,
    color: 'purple',
  },
  {
    month: 'FEB',
    amount: 1300,
    color: 'blue',
  },
  {
    month: 'MAR',
    amount: 1300,
    color: 'green',
  },
  {
    month: 'APR',
    amount: 1200,
    color: 'darkorange',
  },
  {
    month: 'MAY',
    amount: 1400,
    color: 'goldenrod',
  },
  {
    month: 'JUN',
    amount: 987,
    color: 'red',
  },
];

export const CostTrendChart: React.FC<CostTrendChartProps> = ({ data }) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      legend: {
        color: theme.palette.secondary.main,
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: theme.palette.secondary.contrastText,
        padding: '1rem',
        borderRadius: '.25rem',
      },
    });
  });

  const classes = useStyles();

  const legendTheme = (value, entry) => {
    return <span className={classes.legend}>{value}</span>;
  };

  interface CustomTooltipProps {
    active?: boolean;
    payload?: string | any;
    label?: string;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && label) {
      return (
        <div className={classes.tooltip}>
          {`${label} : ${payload[0].value}`}
        </div>
      );
    }
    return null;
  };

  return (
    <LineChart
      width={350}
      height={300}
      margin={{
        top: 24,
        left: -20,
        right: 0,
        bottom: 0,
      }}
      data={staticData}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" stroke={theme.palette.primary.main} />
      <YAxis domain={[500, 1500]} stroke={theme.palette.primary.main} />
      <Tooltip content={<CustomTooltip />} />
      <Legend formatter={legendTheme} />

      <Line
        type="monotone"
        dataKey="amount"
        stroke={theme.palette.secondary.main}
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );
};

export default CostTrendChart;

import { PaletteType } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

export const dark = {
  palette: {
    type: 'dark' as PaletteType,
    primary: {
      main: '#008cb8',
      contrastText: '#66efff',
    },
    secondary: {
      main: '#66efff',
      contrastText: grey[50],
    },
    error: {
      main: red[400],
      contrastText: grey[50],
    },
  },
};

export const light = {
  palette: {
    type: 'light' as PaletteType,
    primary: {
      main: '#00bceb',
      contrastText: '#004d7b',
    },
    secondary: {
      main: '#004d7b',
    },
    error: {
      main: red[800],
      contrastText: grey[50],
    },
  },
};

import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import { PageHeader } from '@cisco-unified-cloud/shared/ui';
import { environment } from '@cisco-unified-cloud/shared/client-env';

/* eslint-disable-next-line */
export interface CloudProps {}

export const Cloud: React.FC<CloudProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        padding: '1rem',
        height: 'calc(100vh - 10.5em)',
        overflow: 'auto',
      },
      frame: {
        border: 0,
        minHeight: '180px',
        minWidth: '180px',
        width: '100%',
        borderRadius: '.5rem',
      },
      frameContainer: {
        flex: 1,
        padding: '1rem',
      },
      frameRow: {
        display: 'flex',
      },
      barChartContainer: {
        flex: 1,
        padding: '1rem',
        background: 'white',
        borderRadius: '.5rem',
      },
    });
  });

  const classes = useStyles();

  /**
   * GRAFANA AIVENCLOUD URL COMPOSITION
   * FOR: https://grafana-uc-alpha-dev.aivencloud.com
   * @description These fragments and requestUrl are for use with the AIVENCLOUD hosted Grafana.
   * TODO: Use these instead of IGNW, when we're ready to switch to Aivencloud
   */
  /*
  const frags = {
    baseUrl: environment.NX_GRAFANA_BASE_URL,
    urlToken: environment.NX_GRAFANA_URL_TOKEN,
    ordId: `virtual-machine?ordId=${environment.NX_GRAFANA_ORG_ID}`,
    rangeFrom: `&from=1617205880899`,
    rangeTo: `&to=1617227480899`,
    varClusterName: `var-clusterName=${environment.NX_GRAFANA_CLUSTER_NAME}`,
  }
  const requestUrl = `${frags.baseUrl}/${frags.urlToken}/${frags.ordId}&${frags.varClusterName}`;
*/

  /**
   * GRAFANA IGNW URL COMPOSITION
   * FOR: https://grafana-uc-alpha-dev.aivencloud.com
   * FOR: https://blueocean-dev-vm.ignw.io
   * @description These fragments and requestUrl are for use with the IGNW hosted Grafana.
   */
  const frags = {
    baseUrl: environment.NX_GRAFANA_BASE_URL,
    urlToken: environment.NX_GRAFANA_URL_TOKEN,
    ordId: `cluster?ordId=${environment.NX_GRAFANA_ORD_ID}`,
    varClusterName: `var-clusterName=${environment.NX_GRAFANA_CLUSTER_NAME}`,
  };
  const requestUrl = `${frags.baseUrl}/${frags.urlToken}/${frags.ordId}&${frags.varClusterName}`;

  const panels = {
    title: 'Overview',
    memoryCapacityTitle: 'Memory Capacity',
    memoryCapacity: `${requestUrl}&panelId=6`,
    memoryUsageTitle: 'Memory Usage',
    memoryUsage: `${requestUrl}&panelId=8`,
    cpuCapacityTitle: 'CPU Capacity',
    cpuCapacity: `${requestUrl}&panelId=2`,
    cpuUsageTitle: 'CPU Usage',
    cpuUsage: `${requestUrl}&panelId=10`,
    nodesTitle: 'Nodes',
    nodes: `${requestUrl}&panelId=22`,
    memoryUsageGraphTitle: 'Memory Usage',
    memoryUsageGraph: `${requestUrl}&panelId=16`,
    cpuUsageGraphTitle: 'CPU Usage',
    cpuUsageGraph: `${requestUrl}&panelId=18`,
  };

  return (
    <>
      <PageHeader
        icon={<DeveloperBoardIcon />}
        title="Cloud"
        subtitle="Update 1 min ago"
      ></PageHeader>
      <div className={classes.root}>
        <div className={classes.frameRow}>
          <div className={classes.frameContainer}>
            <iframe
              className={classes.frame}
              src={panels.memoryCapacity}
              frameBorder="0"
            />
          </div>
          <div className={classes.frameContainer}>
            <iframe
              className={classes.frame}
              src={panels.memoryUsage}
              frameBorder="0"
            />
          </div>
          <div className={classes.frameContainer}>
            <iframe
              className={classes.frame}
              src={panels.cpuCapacity}
              frameBorder="0"
            />
          </div>
          <div className={classes.frameContainer}>
            <iframe
              className={classes.frame}
              src={panels.cpuUsage}
              frameBorder="0"
            />
          </div>
        </div>
        <div className={classes.frameRow}>
          <div className={classes.frameContainer}>
            <iframe
              className={classes.frame}
              src={panels.nodes}
              frameBorder="0"
            />
          </div>
        </div>
        <div className={classes.frameRow}>
          <div className={classes.frameContainer}>
            <iframe
              className={classes.frame}
              src={panels.memoryUsageGraph}
              frameBorder="0"
            />
          </div>
        </div>
        <div className={classes.frameRow}>
          <div className={classes.frameContainer}>
            <iframe
              className={classes.frame}
              src={panels.cpuUsageGraph}
              frameBorder="0"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cloud;

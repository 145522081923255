import { useState } from '@hookstate/core';
import { Validation } from '@hookstate/validation';
import Button from '@material-ui/core/Button';
import { CardProps } from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export type ContainerHelmChartName =
  | 'rabbitmq-7.6.6.tgz'
  | 'redis-10.7.16.tgz'
  | 'mongodb-9.1.1.tgz'
  | 'hipstershop-0.1.0.tgz';

type HelmChartMenuOptions = {
  chartName: ContainerHelmChartName;
  label: string;
}[];

const helmCharts: HelmChartMenuOptions = [
  {
    chartName: 'rabbitmq-7.6.6.tgz',
    label: 'RabbitMQ',
  },
  {
    chartName: 'redis-10.7.16.tgz',
    label: 'Redis',
  },
  {
    chartName: 'mongodb-9.1.1.tgz',
    label: 'MongoDB',
  },
  {
    chartName: 'hipstershop-0.1.0.tgz',
    label: 'Online Boutique',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      maxWidth: 350,
    },
    fields: {
      marginTop: theme.spacing(1.5),
      width: '100%',
    },
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    cardTitle: {
      fontFamily: 'Futura',
      fontWeight: 600,
      marginBottom: '1rem',
    },
  })
);

export interface CreateContainerFields {
  containerName: string;
  containerNameStarted: boolean;
  chart: string;
  namespace: string;
  namespaceStarted: boolean;
  releaseName: string;
  releaseNameStarted: boolean;
  source: string;
  sourceStarted: boolean;
  description: string;
  replicas: number;
  replicasStarted: boolean;
}

export interface CreateContainerProps extends Partial<CardProps> {
  onCreate?: (data: CreateContainerFields) => void;
  onCancel?: () => void;
  selectedHelmChart?: ContainerHelmChartName;
}

export const CreateContainer: React.FC<CreateContainerProps> = ({
  children,
  onCreate,
  onCancel,
  selectedHelmChart,
}) => {
  const classes = useStyles();
  const formSubmitted = useState<boolean>(false);
  const formState = useState<CreateContainerFields>({
    containerName: '',
    containerNameStarted: false,
    chart: selectedHelmChart || 'rabbitmq-7.6.6.tgz',
    namespace: '',
    namespaceStarted: false,
    releaseName: '',
    releaseNameStarted: false,
    source: '',
    sourceStarted: false,
    description: '',
    replicas: 1,
    replicasStarted: false,
  });
  formState.attach(Validation);

  Validation(formState.releaseName).validate((releaseName) => {
    return (
      releaseName !== '' &&
      Boolean(releaseName.match(/^[a-z0-9]/)) &&
      Boolean(releaseName.match(/[a-z0-9]$/)) &&
      !releaseName.match(/[^a-z0-9-.]+/)
    );
  }, 'Release name is required, can only container lowercase letters, numbers, . or -, and must start and end with a lowercase letter or number');

  const handleSubmit = () => {
    if (!Validation(formState.releaseName).valid()) {
      formState.releaseNameStarted.set(true);
      return;
    }

    formSubmitted.set(true);

    onCreate && onCreate({ ...formState.value });
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const releaseNameError =
    formState.releaseNameStarted.value &&
    !Validation(formState.releaseName).valid() &&
    Validation(formState.releaseName).errors()[0]?.message;

  const containerNameError =
    formState.containerNameStarted.value &&
    !Validation(formState.containerName).valid() &&
    Validation(formState.containerName).errors()[0]?.message;

  const namespaceError =
    formState.namespaceStarted.value &&
    !Validation(formState.namespace).valid() &&
    Validation(formState.namespace).errors()[0]?.message;

  const sourceError =
    formState.sourceStarted.value &&
    !Validation(formState.source).valid() &&
    Validation(formState.source).errors()[0]?.message;

  const replicasError =
    formState.replicasStarted.value &&
    !Validation(formState.replicas).valid() &&
    Validation(formState.replicas).errors()[0]?.message;
  return (
    <>
      <CardContent>
        <Typography className={classes.cardTitle}>Create Container</Typography>
        <TextField
          className={classes.fields}
          id="create-container-name"
          label="Container Name"
          variant="outlined"
          value={formState.containerName.value}
          error={Boolean(containerNameError)}
          helperText={containerNameError}
          onChange={(event) =>
            formState.merge({
              containerName: event.target.value.trim(),
              containerNameStarted: true,
            })
          }
        />
        <TextField
          className={classes.fields}
          id="create-release-name"
          label="Release Name"
          variant="outlined"
          value={formState.releaseName.value}
          error={Boolean(releaseNameError)}
          helperText={releaseNameError}
          onChange={(event) =>
            formState.merge({
              releaseName: event.target.value.trim(),
              releaseNameStarted: true,
            })
          }
        />
        <TextField
          className={classes.fields}
          id="create-namespace"
          label="Namespace"
          variant="outlined"
          value={formState.namespace.value}
          error={Boolean(namespaceError)}
          helperText={namespaceError}
          onChange={(event) =>
            formState.merge({
              namespace: event.target.value.trim(),
              namespaceStarted: true,
            })
          }
        />
        <TextField
          className={classes.fields}
          id="create-source"
          label="Source"
          variant="outlined"
          value={formState.source.value}
          error={Boolean(sourceError)}
          helperText={sourceError}
          onChange={(event) =>
            formState.merge({
              source: event.target.value.trim(),
              sourceStarted: true,
            })
          }
        />
        <FormControl variant="outlined" className={classes.fields}>
          <InputLabel id="create-virtual-machine-helm-chart">
            Helm Chart
          </InputLabel>
          <Select
            labelId="helm-chart-name-select-label"
            id="helm-chart-name-select-outlined"
            value={formState.chart.value}
            onChange={(event) =>
              formState.chart.set(event.target.value as ContainerHelmChartName)
            }
            label="Helm Chart Name"
            disabled={!!selectedHelmChart}
          >
            {helmCharts.map((hcm) => {
              return (
                <MenuItem key={hcm.chartName} value={hcm.chartName}>
                  {hcm.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          className={classes.fields}
          id="create-container-description"
          label="Description"
          variant="outlined"
          multiline={true}
          value={formState.description.value}
          onChange={(event) =>
            formState.merge({
              description: event.target.value,
            })
          }
        />
        <TextField
          className={classes.fields}
          id="create-replicas"
          label="Replicas"
          variant="outlined"
          value={formState.replicas.value}
          error={Boolean(replicasError)}
          helperText={replicasError}
          onChange={(event) =>
            formState.merge({
              replicas: parseInt(event.target.value.trim(), 10),
              replicasStarted: true,
            })
          }
        />
        {children}
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => handleCancel()}>Cancel</Button>
        <div style={{ width: '.25rem' }}></div>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => handleSubmit()}
          disabled={
            !!formSubmitted.get() || !!Validation(formState).errors().length
          }
        >
          Create
        </Button>
      </CardActions>
    </>
  );
};

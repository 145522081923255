import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { useState } from '@hookstate/core';
import { useKeycloak } from '@react-keycloak/web';
import {
  SvgIconProps,
  CssBaseline,
  Box,
  Button,
  Drawer,
  Paper,
} from '@material-ui/core';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import AppBar from '../app-bar/app-bar';
import AppDrawer from '../app-drawer/app-drawer';
import { dark, light } from '../../theme/theme';
import { environment } from '@cisco-unified-cloud/shared/client-env';

export interface AppLayoutProps {
  children: JSX.Element | React.ReactNode;
  title?: string;
  icons?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    url?: string;
  }[];
  menu?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    url?: string;
  }[];
}

export const AppLayout: FunctionComponent<AppLayoutProps> = (props) => {
  const { keycloak } = useKeycloak();

  const darkMode = useState(true);

  /* eslint-disable-next-line */
  const darkTheme = createMuiTheme(dark);

  /* eslint-disable-next-line */
  const lightTheme = createMuiTheme(light);

  const theme = darkMode.get() ? darkTheme : lightTheme;

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        display: 'flex',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
      drawer: {
        width: '16rem',
      },
      drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 240,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      fixedHeight: {
        height: 240,
      },
      main: {
        flexGrow: 1,
      },
      page: {
        paddingTop: '4rem',
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
    });
  });

  const classes = useStyles();

  const open = useState(true);

  const handleDrawerOpen = () => {
    open.set(true);
  };

  const handleDrawerClose = () => {
    open.set(false);
  };

  const toggleDrawer = () => {
    open ? handleDrawerClose() : handleDrawerOpen();
  };

  const handleLogout = () => {
    keycloak.logout({ redirectUri: environment.NX_KEYCLOAK_LOGOUT_URL });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper className={classes.root}>
        <AppBar
          title={props.title}
          icons={props.icons}
          handleDrawerClick={toggleDrawer}
          handleLogoutClick={handleLogout}
        />
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(
              classes.drawerPaper,
              !open.get() && classes.drawerPaperClose
            ),
          }}
          open={open.get()}
        >
          <AppDrawer
            menu={props.menu}
            checked={darkMode.get()}
            onChange={() => darkMode.set(!darkMode.get())}
          />
        </Drawer>
        <main className={classes.main}>
          <Box className={classes.page}>{props.children}</Box>
        </main>
      </Paper>
    </ThemeProvider>
  );
};

export default AppLayout;

import React, { FunctionComponent } from 'react';
import { useState } from '@hookstate/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  SvgIconProps,
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  ChevronRight as ChevronRightIcon,
  ExitToApp as ExitToAppIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';

export interface AppBarProps {
  title?: string;
  icons?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    url?: string;
  }[];
  handleDrawerClick: () => void;
  handleLogoutClick: () => void;
}

export const AppBar: FunctionComponent<AppBarProps> = (props) => {
  const anchorEl = useState(null);

  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        display: 'flex',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
      appBar: {
        zIndex: 10000,
      },
      toolBar: {
        display: 'flex',
        alignItems: 'center',
      },
      pageLogo: {
        margin: '0 1rem 0 1.15rem',
      },
      projectName: {
        marginLeft: '1rem',
        fontFamily: 'Futura',
        fontWeight: 600,
        fontSize: '1.15em',
      },
      fOne: {
        flex: '1',
      },
      brandText: {
        fontFamily: 'Futura',
      },
    });
  });

  const classes = useStyles();

  const handleChildDrawerClick = (event) => {
    props.handleDrawerClick();
    anchorEl.set(event.currentTarget);
  };

  const handleChildLogoutClick = (event) => {
    props.handleLogoutClick();
    anchorEl.set(null);
  };

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleChildDrawerClick}
        >
          <MenuIcon />
        </IconButton>
        <Box className={classes.pageLogo}>
          <span className={classes.brandText}>
            <strong>Unified Cloud</strong>
          </span>
        </Box>
        <IconButton color="secondary">
          <ChevronRightIcon />
        </IconButton>
        <Box className={classes.projectName}>{props.title}</Box>
        <RouterLink to="/projects">
          <IconButton color="secondary">
            <KeyboardArrowDownIcon />
          </IconButton>
        </RouterLink>
        {props.icons && <div className={classes.fOne}></div>}
        {props.icons &&
          props.icons.map((o, k) => (
            <RouterLink to={o.url} key={o.label}>
              <IconButton key={o.label} color="secondary">
                <Box
                  component={o.icon}
                  style={{ color: theme.palette.secondary.main }}
                />
              </IconButton>
            </RouterLink>
          ))}
        <IconButton color="secondary" onClick={handleChildLogoutClick}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;

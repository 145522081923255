import React from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface ClientUiProps {}

const StyledClientUi = styled.div`
  color: pink;
`;

export function ClientUi(props: ClientUiProps) {
  return (
    <StyledClientUi>
      <h1>Welcome to client-ui!</h1>
    </StyledClientUi>
  );
}

export default ClientUi;

import React, { FunctionComponent } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Link as RouterLink } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import PaletteIcon from '@material-ui/icons/Palette';

export interface AppDrawerProps {
  checked?: boolean;
  onChange: () => void;
  menu?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    url?: string;
  }[];
}

export const AppDrawer: FunctionComponent<AppDrawerProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      listBox: {
        flex: 1,
        paddingTop: '4rem',
        backgroundColor: 'rgba(0,120,200,0.15)',
      },
      list: {
        minWidth: '16rem',
      },
      listItem: {
        paddingLeft: '1.5rem',
      },
      routerLink: {
        textDecoration: 'none',
      },
      themeItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 .5rem 0 1.5rem',
      },
      themeItemLabel: {
        flex: '1',
        marginLeft: '2rem',
      },
    });
  });

  const classes = useStyles();

  return (
    <Box className={classes.listBox}>
      <List className={classes.list}>
        {props.menu.map((o, k) => (
          <RouterLink
            to={o.url}
            className={classes.routerLink}
            style={{ color: theme.palette.secondary.main }}
            key={o.label}
          >
            <ListItem button className={classes.listItem}>
              <ListItemIcon>
                <Box
                  component={o.icon}
                  style={{ color: theme.palette.secondary.main }}
                />
              </ListItemIcon>
              <ListItemText primary={o.label} />
            </ListItem>
          </RouterLink>
        ))}
        <Divider />
      </List>
      <div
        className={classes.themeItem}
        style={{ color: theme.palette.secondary.main }}
        onClick={props.onChange}
      >
        <PaletteIcon />
        <span className={classes.themeItemLabel}>Theme</span>
        <Switch checked={props.checked} onChange={props.onChange} />
      </div>
    </Box>
  );
};

export default AppDrawer;

import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RouterIcon from '@material-ui/icons/Router';
import { PageHeader } from '@cisco-unified-cloud/shared/ui';

/* eslint-disable-next-line */
export interface NetworkingProps {}

export const Networking: React.FC<NetworkingProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      buttonTextFix: {
        marginTop: '.15rem',
      },
      grid: {
        padding: '1rem',
      },
    });
  });

  const classes = useStyles();
  return (
    <>
      <PageHeader
        icon={<RouterIcon />}
        title="Networking"
        subtitle="Update 1 min ago"
      >
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          endIcon={<OpenInNewIcon />}
        >
          <span className={classes.buttonTextFix}>View ACI Fabric</span>
        </Button>
      </PageHeader>
      <Grid container className={classes.grid}>
        <Grid item>
          <em>...FPO...</em>
        </Grid>
      </Grid>
    </>
  );
};

export default Networking;

import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';

/* eslint-disable-next-line */
export interface LoginProps {}

export const Login: FunctionComponent<LoginProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        marginLeft: 0,
        marginRight: 0,
      },
      buttonTextFix: {
        marginTop: '.15rem',
      },
      loginTop: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        padding: '7rem 1rem 1rem 1rem',
      },
      loginBottom: {
        height: '36%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        padding: '1rem',
        position: 'relative',
        zIndex: 10000,
      },
      loginTitle: {
        fontFamily: 'Futura',
        margin: 0,
        padding: 0,
        position: 'relative',
        top: '-.5rem',
        zIndex: 10000,
        opacity: 0,
        animation: `$loginTitleAnim 1s ease-in forwards`,
      },
      '@keyframes loginTitleAnim': {
        '0%': {
          opacity: 0,
        },
        '100%': {
          opacity: 1.0,
        },
      },
      loginSubTitle: {
        fontFamily: 'Futura',
        margin: 0,
        padding: 0,
        position: 'relative',
        zIndex: 10000,
      },
      cloud: {
        position: 'absolute',
        top: '5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 50,
        opacity: 0,
        color: 'white',
        animation: `$cloudAnim 1s ease-in forwards`,
      },
      '@keyframes cloudAnim': {
        '0%': {
          opacity: 0,
        },
        '100%': {
          opacity: '0.75',
        },
      },
      hexagons: {
        position: 'relative',
        zIndex: 500,
      },
      hexagonsTop: {},
      hexagonsBottom: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-5rem',
      },
      hexagon: {
        color: theme.palette.primary.main,
        fontSize: '6rem',
        position: 'relative',
      },
      hex1: {
        color: theme.palette.primary.main,
        position: 'relative',
        fontSize: '.5rem',
        opacity: 0,
        top: '-10rem',
        left: '-10rem',
        animation: `$hex1Anim .5s ease-in forwards`,
        animationDelay: '.1s',
        zIndex: 100,
      },
      hex2: {
        color: theme.palette.primary.main,
        position: 'relative',
        fontSize: '.5rem',
        opacity: 0,
        top: '-10rem',
        right: '-10rem',
        animation: `$hex2Anim .4s ease-in forwards`,
        animationDelay: '.2s',
        zIndex: 200,
      },
      hex3: {
        color: theme.palette.primary.main,
        position: 'relative',
        fontSize: '.5rem',
        opacity: 0,
        bottom: '-20rem',
        animation: `$hex3Anim .3s ease-in forwards`,
        animationDelay: '.3s',
        zIndex: 300,
      },
      '@keyframes hex1Anim': {
        '0%': {
          fontSize: '.5rem',
          opacity: 0,
          top: '-10rem',
          left: '-10rem',
        },
        '100%': {
          fontSize: '6rem',
          opacity: 1,
          top: 0,
          left: 0,
        },
      },
      '@keyframes hex2Anim': {
        '0%': {
          fontSize: '.5rem',
          opacity: 0,
          top: '-10rem',
          right: '-10rem',
        },
        '100%': {
          fontSize: '6rem',
          opacity: 1,
          top: 0,
          right: 0,
        },
      },
      '@keyframes hex3Anim': {
        '0%': {
          fontSize: '.5rem',
          opacity: 0,
          bottom: '-20rem',
        },
        '100%': {
          fontSize: '6rem',
          opacity: 1,
          bottom: 0,
        },
      },
      loginButton: {
        position: 'relative',
        zIndex: 10000,
        opacity: 0,
        animation: `$loginButtonAnim .2s ease-in-out forwards`,
        animationDelay: '.5s',
      },
      '@keyframes loginButtonAnim': {
        '0%': {
          opacity: 0,
        },
        '100%': {
          opacity: 1.0,
        },
      },
    })
  );

  const classes = useStyles(props);

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.loginTop}>
          <span className={classes.cloud}>
            <CloudCircleIcon style={{ fontSize: '30rem' }} />
          </span>
          <div className={classes.hexagons}>
            <div className={classes.hexagonsTop}>
              <span className={classes.hex1}>&#x2B22;</span>
              <span className={classes.hex2}>&#x2B22;</span>
            </div>
            <div className={classes.hexagonsBottom}>
              <span className={classes.hex3}>&#x2B22;</span>
            </div>
          </div>

          <h1 className={classes.loginTitle}>Unified Cloud</h1>
          <Link to="/dashboard">
            <Button
              className={classes.loginButton}
              size="small"
              variant="outlined"
              color="primary"
            >
              <span className={classes.buttonTextFix}>Login</span>
            </Button>
          </Link>
        </div>
        <div className={classes.loginBottom}></div>
      </div>
    </>
  );
};

export default Login;

export const staticData = [
  {
    name: 'Project Juan',
    id: '12345678910',
    capacity: '72%',
    errors: '0',
    utilization: '59%',
    storage: '34%',
    vms: '16',
  },
  {
    name: 'Project Tu',
    id: '12345678910',
    capacity: '72%',
    errors: '0',
    utilization: '59%',
    storage: '34%',
    vms: '16',
  },
  {
    name: 'Project Tree',
    id: '12345678910',
    capacity: '72%',
    errors: '0',
    utilization: '59%',
    storage: '34%',
    vms: '16',
  },
  {
    name: 'Project Fore',
    id: '12345678910',
    capacity: '72%',
    errors: '0',
    utilization: '59%',
    storage: '34%',
    vms: '16',
  },
  {
    name: 'Project Fiver',
    id: '12345678910',
    capacity: '72%',
    errors: '0',
    utilization: '59%',
    storage: '34%',
    vms: '16',
  },
];

export const staticFavoriteData = [
  {
    name: 'Project Fiver',
    id: '12345678910',
    capacity: '72%',
    errors: '0',
    utilization: '59%',
    storage: '34%',
    vms: '16',
  },
];

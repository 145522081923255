import React, { FunctionComponent } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { PageTextBlock } from '@cisco-unified-cloud/shared/ui';

export interface ProjectCardProps {
  name: string;
  id: string;
  capacity: string;
  errors: string;
  utilization: string;
  storage: string;
  vms: string;
}

export const ProjectCard: FunctionComponent<ProjectCardProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      dataRows: {
        paddingBottom: '1rem',
      },
      dataRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      label: {
        color: theme.palette.primary.main,
      },
      value: {
        color: theme.palette.primary.contrastText,
      },
      projectCard: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '.5rem',
        padding: '1rem',
        width: '16rem',
        transition: '.2s ease-in-out all',
        '&:hover': {
          border: `1px solid ${theme.palette.secondary.main}`,
          cursor: 'pointer',
        },
      },
    });
  });

  const classes = useStyles();
  return (
    <div className={classes.projectCard}>
      <PageTextBlock
        title={props.name}
        subtitle={`ID: ${props.id}`}
        size="xs"
      />
      <div className={classes.dataRows}>
        <div className={classes.dataRow}>
          <span className={classes.label}>Capacity</span>
          <span className={classes.value}>{props.capacity}</span>
        </div>
        <div className={classes.dataRow}>
          <span className={classes.label}>Errors</span>
          <span className={classes.value}>{props.errors}</span>
        </div>
        <div className={classes.dataRow}>
          <span className={classes.label}>Utilization</span>
          <span className={classes.value}>{props.utilization}</span>
        </div>
        <div className={classes.dataRow}>
          <span className={classes.label}>Storage</span>
          <span className={classes.value}>{props.storage}</span>
        </div>
        <div className={classes.dataRow}>
          <span className={classes.label}>VMs</span>
          <span className={classes.value}>{props.vms}</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

import React, { FunctionComponent } from 'react';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import clsx from 'clsx';

export interface PageTextBlockProps {
  body?: string;
  image?: string;
  label?: string | number;
  size?: string;
  subtitle?: string | number;
  title?: string | number;
}

export const PageTextBlock: FunctionComponent<PageTextBlockProps> = (props) => {
  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      textBlock: {},
      textBlockTitle: {
        color: theme.palette.secondary.main,
        fontFamily: 'Futura',
        fontSize: '1.5em',
        fontWeight: 500,
      },
      textBlockSubtitle: {
        color: theme.palette.text.secondary,
        fontSize: '1.15em',
        fontWeight: 400,
        marginBottom: '.5rem',
      },
      textBlockStatus: {
        color: theme.palette.text.secondary,
        fontSize: '0.9175em',
        fontWeight: 300,
        marginBottom: '1rem',
      },
      textBlockBody: {
        fontSize: '1em',
        fontWeight: 300,
        marginBottom: '1rem',
      },
      xs: {
        fontSize: '90%',
      },
      sm: {
        fontSize: '100%',
      },
      md: {
        fontSize: '120%',
      },
      lg: {
        fontSize: '160%',
      },
      xl: {
        fontSize: '200%',
      },
    });
  });

  const classes = useStyles();

  const getClasses = (size?: string) => {
    switch (size) {
      case 'xs':
        return { [classes.xs]: true };
      case 'sm':
        return { [classes.sm]: true };
      case 'md':
        return { [classes.md]: true };
      case 'lg':
        return { [classes.lg]: true };
      case 'xl':
        return { [classes.xl]: true };
      default:
        return { [classes.sm]: true };
    }
  };

  return (
    <div className={classes.textBlock}>
      <span className={clsx({ ...getClasses(props.size) })}>
        {props.title && (
          <div className={classes.textBlockTitle}>{props.title}</div>
        )}
        {props.subtitle && (
          <div className={classes.textBlockSubtitle}>{props.subtitle}</div>
        )}
        {props.label && (
          <div className={classes.textBlockStatus}>{props.label}</div>
        )}
        {props.body && (
          <div className={classes.textBlockBody}>{props.body}</div>
        )}
      </span>
    </div>
  );
};

export default PageTextBlock;

import React from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from '@cisco-unified-cloud/client-ui';

import App from './app/app';

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);

// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  NX_CLIENT_URL: 'http://localhost:4200',
  NX_COMPUTE_API_HOST: 'http://localhost:3338',
  NX_GRAFANA_BASE_URL: 'https://blueocean-dev-vm.ignw.io/monitoring/d-solo',
  NX_GRAFANA_CLUSTER_NAME: 'Pod3-HX01',
  NX_GRAFANA_ORD_ID: '1',
  NX_GRAFANA_URL_TOKEN: 'oyWhi0MMz',
  NX_IAM_API_URL: 'http://localhost:4001',
  NX_KEYCLOAK_API_CLIENT_SECRET: 'ed171f74-bcba-44ee-90a0-a8f259a60626',
  NX_KEYCLOAK_BASE_URL: 'http://localhost:8080/auth',
  NX_KEYCLOAK_CLIENT_ID: 'client',
  NX_KEYCLOAK_LOGOUT_URL:
    'http://localhost:8080/auth/realms/unified-cloud/protocol/openid-connect/logout?redirect_uri=http://localhost:4200',
  NX_KEYCLOAK_REALM: 'unified-cloud',
};

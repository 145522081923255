import React, { FunctionComponent } from 'react';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';

export interface PageHeaderProps {
  children?: React.ReactNode;
  icon?: JSX.Element;
  subtitle?: string | number;
  title?: string | number;
}

export const PageHeader: FunctionComponent<PageHeaderProps> = (props) => {
  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      pageHeader: {
        display: 'flex',
        minHeight: '4rem',
        padding: '1rem 2rem 1rem 2rem',
      },
      pageHeaderLeft: {
        display: 'flex',
        alignItems: 'center',
      },
      pageHeaderRight: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      pageHeaderIcon: {
        marginRight: '1.5rem',
        backgroundColor: theme.palette.primary.main,
      },
      pageHeaderTitleStack: {
        display: 'flex',
        flexDirection: 'column',
      },
      pageHeaderTitle: {
        color: theme.palette.primary.main,
        fontFamily: 'Futura',
        fontSize: '1.15em',
        fontWeight: 900,
      },
      pageHeaderSubtitle: {
        fontSize: '0.9175em',
        fontWeight: 300,
      },
      pageHeaderBorder: {
        height: '1px',
        backgroundColor: theme.palette.divider,
      },
    });
  });

  const classes = useStyles();

  return (
    <>
      <div className={classes.pageHeader}>
        <div className={classes.pageHeaderLeft}>
          {props.icon && (
            <Avatar className={classes.pageHeaderIcon}>{props.icon}</Avatar>
          )}
          <div className={classes.pageHeaderTitleStack}>
            {props.title && (
              <div className={classes.pageHeaderTitle}>{props.title}</div>
            )}
            {props.subtitle && (
              <div className={classes.pageHeaderSubtitle}>{props.subtitle}</div>
            )}
          </div>
        </div>
        {props.children && (
          <div className={classes.pageHeaderRight}>{props.children}</div>
        )}
      </div>
      <div className={classes.pageHeaderBorder}></div>
    </>
  );
};

export default PageHeader;

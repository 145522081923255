import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AlarmIcon from '@material-ui/icons/Alarm';
import { PageHeader } from '@cisco-unified-cloud/shared/ui';

/* eslint-disable-next-line */
export interface AlarmsProps {}

export const Alarms: React.FC<AlarmsProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      grid: {
        padding: '1rem',
      },
    });
  });

  const classes = useStyles();
  return (
    <>
      <PageHeader
        icon={<AlarmIcon />}
        title="Alarms"
        subtitle="Update 1 min ago"
      ></PageHeader>
      <Grid container className={classes.grid}>
        <Grid item>
          <em>...FPO...</em>
        </Grid>
      </Grid>
    </>
  );
};

export default Alarms;

interface VM {
  vmName: string;
  project?: string;
  powerState: number;
  numCpus: number;
  memoryCapacity: number;
  diskCapacity: number;
  tags: string;
}

export const vmTableMockData: VM[] = [
  {
    vmName: 'crystal_db2',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 2147483648,
    diskCapacity: 134565328,
    tags: 'project=hrms',
  },
  {
    vmName: 'Dev-test-tes-app',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 134459861,
    tags: '',
  },
  {
    vmName: 'crystal-2',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4194304,
    diskCapacity: 134561230,
    tags: 'project=hrms',
  },
  {
    vmName: 'crystal_db3',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 2147483648,
    diskCapacity: 134548432,
    tags: 'project=hrms',
  },
  {
    vmName: 'unified-gitlab-remote-runner-1',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 34899509671,
    tags: '',
  },
  {
    vmName: 'ccp-tenant-image-1.16.3-ubuntu18-6.1.1',
    powerState: 2,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 52280902159,
    tags:
      'uc-external|CCP Cluster Image name=ccp-tenant-image-1.16.3-ubuntu18-6.1.1',
  },
  {
    vmName: 'kcp-vm-6.1.1',
    powerState: 1,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 42950034960,
    tags: 'project=general-it',
  },
  {
    vmName: 'kcp-pod4-mastere183eea275',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 48428202611,
    tags: 'project=general-it',
  },
  {
    vmName: 'kcp-pod4-workerf763cf5d40',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 53780006551,
    tags: 'project=general-it',
  },
  {
    vmName: 'kcp-pod4-worker8126d5d516',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 109532324264,
    tags: 'project=general-it',
  },
  {
    vmName: 'kcp-pod4-worker0dca1e87f8',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 116219759956,
    tags: 'project=general-it',
  },
  {
    vmName: 'athena-611-master-gro-a76ae1c113',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 48423609978,
    tags: '',
  },
  {
    vmName: 'athena-611-node-group-27e749f60c',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 117143329440,
    tags: '',
  },
  {
    vmName: 'athena-611-node-group-cd60e04fd2',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 117921008638,
    tags: '',
  },
  {
    vmName: 'tiges-cluster-master-gro-e11febf29d',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 48609915517,
    tags: 'project=general-it',
  },
  {
    vmName: 'tiges-cluster-node-group-71ee04c549',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 49480510589,
    tags: 'project=general-it',
  },
  {
    vmName: 'tiges-cluster-node-group-1d2d8e0d19',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 50571590814,
    tags: 'project=general-it',
  },
  {
    vmName: 'tiges-cluster-node-group-93646d6dd8',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 69886490361,
    tags: 'project=general-it',
  },
  {
    vmName: 'mon21-4pm-1',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 4194304,
    diskCapacity: 134565328,
    tags: '',
  },
  {
    vmName: 'windows-2016-template',
    powerState: 2,
    numCpus: 1,
    memoryCapacity: 4294967296,
    diskCapacity: 91520155859,
    tags: '',
  },
  {
    vmName: 'stCtlVM-WZP221408SU',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 51539607552,
    diskCapacity: 2801574892,
    tags: '',
  },
  {
    vmName: 'stCtlVM-WZP221408ND',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 51539607552,
    diskCapacity: 2801808156,
    tags: '',
  },
  {
    vmName: 'stCtlVM-WZP22131038',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 51539607552,
    diskCapacity: 2801766378,
    tags: '',
  },
  {
    vmName: 'vcsa-jump01',
    powerState: 2,
    numCpus: 1,
    memoryCapacity: 4294967296,
    diskCapacity: 38873383434,
    tags: '',
  },
  {
    vmName: 'athena-demo-workload',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 33313976856,
    tags: '',
  },
  {
    vmName: 'vmware-cloudcenter-centos6-worker1-release-5.4.0',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 127440720860,
    tags: '',
  },
  {
    vmName: 'suite-5.2.0-installer',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 8589934592,
    diskCapacity: 89863975423,
    tags: '',
  },
  {
    vmName: 'CCS-5.2.0-Base-Image (75e3c38b895433374bfa5caefcb86dff)',
    powerState: 2,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 52280902199,
    tags: '',
  },
  {
    vmName: 'ubuntu18.04-template',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 38181510645,
    tags: '',
  },
  {
    vmName: 'ubuntu16.04-template-v1',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 37846538835,
    tags: '',
  },
  {
    vmName: 'Athena-test',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 17222032424,
    tags: '',
  },
  {
    vmName: 'Athena-test-template',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 19530859057,
    tags: '',
  },
  {
    vmName: 'ubuntu18.04-thin',
    powerState: 2,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 111777675642,
    tags: '',
  },
  {
    vmName: 'athena-wla-bizhours-power',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 17180235805,
    tags: '',
  },
  {
    vmName: 'athena-wla-hotplug-cpu-24h',
    powerState: 1,
    numCpus: 7,
    memoryCapacity: 1073741824,
    diskCapacity: 17180205621,
    tags: '',
  },
  {
    vmName: 'athena-wla-hotplug-memory-2d',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 3221225472,
    diskCapacity: 17180224055,
    tags: '',
  },
  {
    vmName: 'athena-wla-power-cycle-1w',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 17180203060,
    tags: '',
  },
  {
    vmName: 'athena-wla-base',
    powerState: 2,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 18472288810,
    tags: '',
  },
  {
    vmName: 'Intersight-api-vm2',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 134253527,
    tags: '',
  },
  {
    vmName: 'sap_front2',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 4194304,
    diskCapacity: 134492623,
    tags: 'project=sap',
  },
  {
    vmName: 'sap_db',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 4194304,
    diskCapacity: 134562251,
    tags: 'project=sap',
  },
  {
    vmName: 'sap_app1',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 4194304,
    diskCapacity: 134510541,
    tags: 'project=sap',
  },
  {
    vmName: 'sap_front1',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 4194304,
    diskCapacity: 134546383,
    tags: 'project=sap',
  },
  {
    vmName: 'sap_app2',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 4194304,
    diskCapacity: 134562253,
    tags: 'project=sap',
  },
  {
    vmName: 'mon21-small-1',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 4194304,
    diskCapacity: 134520274,
    tags: '',
  },
  {
    vmName: 'unified-gitlab-remote-runner-2',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 17180181563,
    tags: '',
  },
  {
    vmName: 'ignw-hxpel-pod4-vc',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 324256262467,
    tags: '',
  },
  {
    vmName: 'ignw-hpel-pod4-vc-OLD',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 307024889625,
    tags: '',
  },
  {
    vmName: 'athena-611-node-group-c7c47b7d4f',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 170830381372,
    tags: '',
  },
  {
    vmName: 'athena-611-node-group-827bc907ee',
    powerState: 1,
    numCpus: 3,
    memoryCapacity: 17179869184,
    diskCapacity: 127880655426,
    tags: '',
  },
  {
    vmName: 'ubuntu16.04-template',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 36710784402,
    tags: '',
  },
  {
    vmName: 'ubuntu2004_template_base',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 59330634240,
    tags: '',
  },
  {
    vmName: 'ubuntu2004_runner_template',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 59330634319,
    tags: '',
  },
  {
    vmName: 'gitlab-runner-pod4-02',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 44049184590419,
    tags: '',
  },
  {
    vmName: 'gitlab-runner-pod4-01',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 44049184590419,
    tags: '',
  },
  {
    vmName: 'ubuntu1804_template_base',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 59330634240,
    tags: '',
  },
  {
    vmName: 'ubuntu1804_runner_template',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 59330634319,
    tags: '',
  },
  {
    vmName: 'crystal_db',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 2147483648,
    diskCapacity: 134539215,
    tags: '',
  },
  {
    vmName: 'athena-wla-base',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 17180275754,
    tags: '',
  },
  {
    vmName: 'phildemo2020-01',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 16777216000,
    diskCapacity: 53687093293,
    tags: '',
  },
  {
    vmName: 'myubuntu-04',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 50331648000,
    diskCapacity: 53687093289,
    tags: '',
  },
  {
    vmName: 'ui-test-sep30-zyx9',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 53687093296,
    tags: '',
  },
  {
    vmName: 'myubuntu-08',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 4194304000,
    diskCapacity: 53687093289,
    tags: '',
  },
  {
    vmName: 'ubuntu-16-87du',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 53687093292,
    tags: '',
  },
  {
    vmName: 'phil-demo-012021-r32e',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 53687093299,
    tags: '',
  },
  {
    vmName: 'newname09-txvk',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 2147483648,
    diskCapacity: 53687093292,
    tags: '',
  },
  {
    vmName: 'mywin-01',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 107374184487,
    tags: '',
  },
  {
    vmName: 'my-windows-03-x6r7',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 6442450944,
    diskCapacity: 53687093296,
    tags: '',
  },
  {
    vmName: 'SQL-01-prrp',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 6442450944,
    diskCapacity: 53687093289,
    tags: '',
  },
  {
    vmName: 'demo-fri-15-01-4bzc',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 53687093297,
    tags: '',
  },
  {
    vmName: 'my-vm-name-cq7v',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 1073741824,
    diskCapacity: 53687093293,
    tags: '',
  },
  {
    vmName: 'myubuntu-02',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 16777216000,
    diskCapacity: 53687093289,
    tags: '',
  },
  {
    vmName: 'mywindows-07-ojf4',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 6442450944,
    diskCapacity: 53687093295,
    tags: '',
  },
  {
    vmName: 'mywindows-08-flhx',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 6442450944,
    diskCapacity: 53687093295,
    tags: '',
  },
  {
    vmName: 'troy-vm-1-15-2021-9ncd',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 53687093300,
    tags: '',
  },
  {
    vmName: 'windows-demo-wdt1',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 53687093295,
    tags: '',
  },
  {
    vmName: 'andy-23-nl5v',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 53687093290,
    tags: '',
  },
  {
    vmName: 'paas-db-u9si',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 42949675049,
    tags: 'Owner=tige,project=anthos-paas,region=chaska,zone=pod6',
  },
  {
    vmName: 'myubuntu-03',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 13107200000,
    diskCapacity: 53687093289,
    tags: '',
  },
  {
    vmName: 'troy-test-auth-fix-iutr',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 53687093301,
    tags: '',
  },
  {
    vmName: 'myubuntu-09',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2097152000,
    diskCapacity: 53687093289,
    tags: '',
  },
  {
    vmName: 'windows-sep-4dop',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 6442450944,
    diskCapacity: 53687093294,
    tags: '',
  },
  {
    vmName: 'troy-create-vm-test-hdyr',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675061,
    tags: 'zone=pod6,custom-label=blah,region=chaska,project=anthos-paas',
  },
  {
    vmName: 'loadserver-83jf',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 16777216000,
    diskCapacity: 53687093293,
    tags: 'region=chaska,zone=pod6,project=loadserver,application=server_sim',
  },
  {
    vmName: 'troy-create-vm-test-2-mpf4',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675063,
    tags: 'project=anthos-paas,zone=pod6,region=chaska,custom-label=blah',
  },
  {
    vmName: 'troy-label-test-dfbo',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 21474838577,
    tags: 'project=anthos-paas,zone=pod6,region=chaska',
  },
  {
    vmName: 'Testingmultiplesubmissions-jcbc',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675068,
    tags: 'multiple=submissions,region=chaska,project=anthos-paas,zone=pod6',
  },
  {
    vmName: 'hrms-01-81xo',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 53687093290,
    tags: 'owner=tige,project=hrms,zone=pod6,region=chaska',
  },
  {
    vmName: 'aa-hrms-db-4ks9',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675052,
    tags: 'region=chaska,zone=pod6,owner=tige,project=hrms',
  },
  {
    vmName: 'Tyler-Test-eyat',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675052,
    tags: 'owner=Alpha,project=anthos-paas,region=chaska,zone=pod6',
  },
  {
    vmName: 'troy-project-label-test-fa9l',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675065,
    tags: 'project=anthos-paas,zone=pod6,region=chaska',
  },
  {
    vmName: 'wedf3-36r9',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675047,
    tags: 'region=chaska,zone=pod6,project=anthos-paas',
  },
  {
    vmName: 'troy-label-test-eqc6',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675057,
    tags: 'project=anthos-paas,zone=pod6,region=chaska,custom-label=troy',
  },
  {
    vmName: 'admin-fri-22-qdf7',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 42949675054,
    tags: 'demo=customer,zone=pod6,region=chaska,project=anthos-paas',
  },
  {
    vmName: 'Tyler-Test-1i58',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675052,
    tags: 'zone=pod6,project=anthos-paas,Test=Test,region=chaska',
  },
  {
    vmName: 'troy-label-test-g8ug',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675057,
    tags: 'project=anthos-paas,region=chaska,zone=pod6,team=alpha',
  },
  {
    vmName: 'test-vm-jan20-ke0v',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 42949675055,
    tags: 'region=chaska,zone=pod6,project=anthos-paas,owner=tige',
  },
  {
    vmName: 'hrms-939v',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 42949675046,
    tags: 'owner=tige,project=hrms,region=chaska,zone=pod6',
  },
  {
    vmName: 'troy-permissions-test-zbmv',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675063,
    tags: 'project=anthos-paas,region=chaska,zone=pod6',
  },
  {
    vmName: 'philtest01292021-4jk9',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675058,
    tags: 'region=chaska,zone=pod6,project=anthos-paas',
  },
  {
    vmName: 'nate-test-windows',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 6442450944,
    diskCapacity: 53687093295,
    tags: 'zone=pod6,region=chaska,project=anthos-paas',
  },
  {
    vmName: 'admin-fri-22-ksjdf',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 42949675055,
    tags: 'demo=customer,project=HRMS,region=chaska,zone=pod6',
  },
  {
    vmName: 'nate-test-linux',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 21474838572,
    tags: 'region=chaska,testlabelemptyvalue=,project=anthos-paas,zone=pod6',
  },
  {
    vmName: 'thurfeb4-rsgi',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 2147483648,
    diskCapacity: 42949675050,
    tags: 'zone=pod6,project=anthos-paas,region=chaska',
  },
  {
    vmName: 'Test2-t470',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675047,
    tags: 'region=chaska,project=anthos-paas,testing=something,zone=pod6',
  },
  {
    vmName: 'troy-pop-test-lj4z',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675055,
    tags: 'region=chaska,zone=pod6,hello=moto,project=anthos-paas',
  },
  {
    vmName: 'loadserver-a73n',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 16777216000,
    diskCapacity: 53687093293,
    tags: 'zone=pod6,project=loadserver,region=chaska,application=server_sim',
  },
  {
    vmName: 'monfeb15-16na',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675050,
    tags: 'region=chaska,zone=pod6,project=anthos-paas',
  },
  {
    vmName: 'troy-vcenter-connectivity-test-snzs',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675072,
    tags: 'region=chaska,project=anthos-paas,zone=pod6',
  },
  {
    vmName: 'tfproxy-test',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 21474838569,
    tags: 'project=anthos-paas,zone=pod6,testlabelemptyvalue=,region=chaska',
  },
  {
    vmName: 'troy-custom-label-test-haar',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675064,
    tags: 'project=anthos-paas,zone=pod6,foo=bar,region=chaska',
  },
  {
    vmName: 'troy-label-test-2-xqmd',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675059,
    tags:
      'key-with-dashes=value-with-dashes,zone=pod6,region=chaska,project=anthos-paas',
  },
  {
    vmName: 'viva-gitlab-runner-01',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 34359738368,
    diskCapacity: 214748366902,
    tags: '',
  },
  {
    vmName: 'viva-gitlab-runner-03',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 34359738368,
    diskCapacity: 214748366902,
    tags: '',
  },
  {
    vmName: 'viva-gitlab-runner-02',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 34359738368,
    diskCapacity: 214748366902,
    tags: '',
  },
  {
    vmName: 'stCtlVM-WZP2213104Z',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 51539607552,
    diskCapacity: 2784972937,
    tags: '',
  },
  {
    vmName: 'stCtlVM-WZP22120C78',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 51539607552,
    diskCapacity: 2785026259,
    tags: '',
  },
  {
    vmName: 'stCtlVM-WZP2211153R',
    powerState: 1,
    numCpus: 8,
    memoryCapacity: 51539607552,
    diskCapacity: 2784985257,
    tags: '',
  },
  {
    vmName: 'intersight-virtual-appliance-POD3',
    powerState: 1,
    numCpus: 24,
    memoryCapacity: 68719476736,
    diskCapacity: 536871339149,
    tags: '',
  },
  {
    vmName: 'ccp-tenant-image-1.16.3-ubuntu18-6.1.1',
    powerState: 2,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 52283179513,
    tags:
      'uc-external|CCP Cluster Image name=ccp-tenant-image-1.16.3-ubuntu18-6.1.1',
  },
  {
    vmName: 'cisco-opsmgr-2.3.17-cwom',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 34359738368,
    diskCapacity: 538968203784,
    tags: '',
  },
  {
    vmName: 'Win-jump01',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 88201686255,
    tags: '',
  },
  {
    vmName: 'CDW-Linux-VM3',
    powerState: 1,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 42949797454,
    tags: '',
  },
  {
    vmName: 'CDW-Linux-VM2',
    powerState: 1,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 42949721664,
    tags: '',
  },
  {
    vmName: 'CDW-Linux-VM1',
    powerState: 1,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 93597536762,
    tags: '',
  },
  {
    vmName: 'centos8-template',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 42949839893,
    tags: '',
  },
  {
    vmName: 'ubuntu18-template',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 47450523985,
    tags: '',
  },
  {
    vmName: 'pod6-general-dhcp',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21474976273,
    tags: '',
  },
  {
    vmName: 'tige-test-02',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21474996241,
    tags: '',
  },
  {
    vmName: 'dockerce-template',
    powerState: 1,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 41943088688,
    tags: '',
  },
  {
    vmName: 'centos8_template_base',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 59333002781,
    tags: '',
  },
  {
    vmName: 'ubuntu2004_template',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 59333004827,
    tags: '',
  },
  {
    vmName: 'tige-test-20.200',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21475016209,
    tags: '',
  },
  {
    vmName: 'tige-test-22.200',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21474902545,
    tags: '',
  },
  {
    vmName: 'tige-test-21.200',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21474886673,
    tags: '',
  },
  {
    vmName: 'tige-test-20.201',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21474942485,
    tags: '',
  },
  {
    vmName: 'tige-test-08',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21474885137,
    tags: '',
  },
  {
    vmName: 'tige-test-06',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 4294967296,
    diskCapacity: 21474882577,
    tags: '',
  },
  {
    vmName: 'ubuntu16.04-template-v1',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 19533162549,
    tags: '',
  },
  {
    vmName: 'ubuntu16.04-template',
    powerState: 2,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 19533160991,
    tags: '',
  },
  {
    vmName: 'hrms-www-1',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 21474838545,
    tags: '',
  },
  {
    vmName: 'hrms-app-server',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 21474838550,
    tags: '',
  },
  {
    vmName: 'hrms-www-2',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 21474838545,
    tags: '',
  },
  {
    vmName: 'kcp-vm-6.1.1',
    powerState: 1,
    numCpus: 3,
    memoryCapacity: 8589934592,
    diskCapacity: 42949779962,
    tags: '',
  },
  {
    vmName: 'cp-pod3-master81bd1673a2',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 48423284784,
    tags: '',
  },
  {
    vmName: 'cp-pod3-worker3430811e8d',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 118111652432,
    tags: '',
  },
  {
    vmName: 'cp-pod3-worker61ca371860',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 64424556599,
    tags: '',
  },
  {
    vmName: 'cp-pod3-worker34b4442e49',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 8589934592,
    diskCapacity: 96637859888,
    tags: '',
  },
  {
    vmName: 'pod3kc01-master-gro-e1c2a55d0a',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 48423284790,
    tags: '',
  },
  {
    vmName: 'pod3kc01-node-group-098f489b91',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 57013221443,
    tags: '',
  },
  {
    vmName: 'pod3kc01-node-group-ae64d606a6',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 17179869184,
    diskCapacity: 61308191310,
    tags: '',
  },
  {
    vmName: 'ubuntu2004_template_base',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 59332937706,
    tags: '',
  },
  {
    vmName: 'ubuntu2004_runner_template',
    powerState: 2,
    numCpus: 4,
    memoryCapacity: 17179869184,
    diskCapacity: 227105096251,
    tags: '',
  },
  {
    vmName: 'hrms-test-1',
    powerState: 1,
    numCpus: 2,
    memoryCapacity: 2147483648,
    diskCapacity: 21474838546,
    tags: '',
  },
  {
    vmName: 'troy-test-2-18-3c79',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675056,
    tags: 'region=chaska,zone=pod6,project=anthos-paas',
  },
  {
    vmName: 'mike-fri-gcjb',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675050,
    tags: 'project=anthos-paas,region=chaska,zone=pod6',
  },
  {
    vmName: 'troy-keycloak-update-test-j66d',
    powerState: 1,
    numCpus: 1,
    memoryCapacity: 1073741824,
    diskCapacity: 42949675067,
    tags: 'region=chaska,project=anthos-paas,zone=pod6',
  },
];

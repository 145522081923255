import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StorageIcon from '@material-ui/icons/Storage';
import { PageHeader } from '@cisco-unified-cloud/shared/ui';

/* eslint-disable-next-line */
export interface StorageProps {}

export const Storage: React.FC<StorageProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      grid: {
        padding: '1rem',
      },
    });
  });

  const classes = useStyles();
  return (
    <>
      <PageHeader
        icon={<StorageIcon />}
        title="Storage"
        subtitle="Update 1 min ago"
      ></PageHeader>
      <Grid container className={classes.grid}>
        <Grid item>
          <em>...FPO...</em>
        </Grid>
      </Grid>
    </>
  );
};

export default Storage;

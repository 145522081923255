import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AppsIcon from '@material-ui/icons/Apps';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import MonitoringIcon from '@material-ui/icons/Visibility';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PaymentIcon from '@material-ui/icons/Payment';
import PolicyIcon from '@material-ui/icons/Policy';
import RouterIcon from '@material-ui/icons/Router';
import StorageIcon from '@material-ui/icons/Storage';

export const icons = [
  {
    icon: NotificationImportantIcon,
    label: 'Notifications',
    url: '/notifications',
  },
];

export const menu = [
  {
    icon: DashboardIcon,
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    icon: PolicyIcon,
    label: 'IAM',
    url: '/iam',
  },
  {
    icon: DeveloperBoardIcon,
    label: 'Cloud',
    url: '/cloud',
  },
  {
    icon: AccountTreeIcon,
    label: 'Workloads',
    url: '/workloads',
  },
  {
    icon: StorageIcon,
    label: 'Storage',
    url: '/storage',
  },
  {
    icon: RouterIcon,
    label: 'Networking',
    url: '/networking',
  },
  {
    icon: NotificationsNoneIcon,
    label: 'Notifications',
    url: '/notifications',
  },
  {
    icon: PaymentIcon,
    label: 'Billing',
    url: '/billing',
  },
  {
    icon: AppsIcon,
    label: 'Projects',
    url: '/projects',
  },
];

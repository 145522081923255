import React from 'react';
import { useState } from '@hookstate/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Button, Grid, Tab, Tabs } from '@material-ui/core';
import { NotificationsNone as NotificationsNoneIcon } from '@material-ui/icons';
import { PageHeader, TabPanel } from '@cisco-unified-cloud/shared/ui';
import useStyles from './notifications.styles';

/* eslint-disable-next-line */
export interface NotificationsProps {}

export const Notifications: React.FC<NotificationsProps> = (props) => {
  const theme = useTheme();

  const classes = useStyles(theme);

  const value = useState(0);

  const handleTabChange = (_: unknown, newValue: number) => {
    value.set(newValue);
  };

  return (
    <>
      <PageHeader
        icon={<NotificationsNoneIcon />}
        title="Notifications"
        subtitle="Update 1 min ago"
      ></PageHeader>

      <div className={classes.tabsContainer}>
        <Tabs value={value.get()} onChange={handleTabChange}>
          <Tab label="Alarms" className={classes.tab} />
          <Tab label="Alerts" className={classes.tab} />
        </Tabs>
        <TabPanel value={value.get()} index={0} className={classes.tabPanel}>
          <Grid container className={classes.grid} spacing={4}>
            <Grid item key="0">
              ...alarms tab....
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value.get()} index={1} className={classes.tabPanel}>
          <Grid container className={classes.grid} spacing={4}>
            <Grid item key="1">
              ...alerts tab....
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </>
  );
};

export default Notifications;

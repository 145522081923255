import React, { useContext, useEffect } from 'react';
import { useState } from '@hookstate/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Tab, Tabs } from '@material-ui/core';
import {
  AccountTree as AccountTreeIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons';
import { PageHeader, TabPanel } from '@cisco-unified-cloud/shared/ui';
import VmTable from './VmTable';
import ContainerTable from './ContainerTable';
import { vmTableMockData } from '@cisco-unified-cloud/mocks';
import { containerTableMockData } from '@cisco-unified-cloud/mocks';
import {
  CreateVirtualMachine,
  CreateVirtualMachineFields,
} from '../controls/CreateVirtualMachine';
import {
  CreateContainer,
  CreateContainerFields,
} from '../controls/CreateContainer';
import { request } from '../../util/request';
import { SnackBarContext } from '../../providers/SnackbarProvider';
import { environment } from '@cisco-unified-cloud/shared/client-env';

export interface VirtualMachineRequest {
  vmName: string;
  imageName: string;
  cpuCount: number;
  memMiB: number;
  diskSpace: number;
  labels: Record<string, string>;
}

export interface CreateContainerRequest {
  containerName: string;
  chart: string;
  namespace: string;
  releaseName: string;
  source: string;
  description: string;
  replicas: number;
}

/* eslint-disable-next-line */
export interface WorkloadsProps {}

let vmListIntervalId: ReturnType<typeof setInterval>;
let containerListIntervalId: ReturnType<typeof setInterval>;

export const Workloads: React.FC<WorkloadsProps> = (props) => {
  const snackbar = useContext(SnackBarContext);

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      buttonTextFix: {
        marginTop: '.15rem',
      },
      grid: {
        padding: '1rem',
      },
      spc: {
        width: '1rem',
      },
      tab: {
        padding: '0 1rem',
      },
      tabsContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
      tabPanel: {},
    });
  });

  const classes = useStyles();

  const value = useState(0);

  const handleTabChange = (_: unknown, newValue: number) => {
    value.set(newValue);
  };

  const vmList = useState([]);

  useEffect(() => {
    async function loadVms() {
      const theVmListWithProject = vmTableMockData.map((vm) => {
        const noProjectString = 'no project';
        if (vm.tags.length) {
          const tags = vm.tags.split(',');
          const projectTag = tags.filter((tag) => tag.includes('project'));
          if (projectTag.length && projectTag[0].includes('=')) {
            vm.project = projectTag[0].split('=')[1];
          } else {
            vm.project = noProjectString;
          }
        } else {
          vm.project = noProjectString;
        }
        return vm;
      });
      const theVmList = theVmListWithProject;
      vmList.set(theVmList);
    }
    loadVms();
    vmListIntervalId = setInterval(async () => {
      await loadVms();
    }, 5000);
    return () => clearInterval(vmListIntervalId);
  }, []);

  const containerList = useState([]);
  useEffect(() => {
    async function loadContainers() {
      const theList = containerTableMockData;
      containerList.set(theList);
    }
    loadContainers();
    containerListIntervalId = setInterval(async () => {
      await loadContainers();
    }, 5000);
    return () => clearInterval(containerListIntervalId);
  }, []);

  const containerDialogOpen = useState(false);
  const closeContainerDialog = () => containerDialogOpen.set(false);
  const createContainer = async (
    data: CreateContainerFields
  ): Promise<void> => {
    try {
      const body: CreateContainerRequest = {
        containerName: data.containerName,
        chart: data.chart,
        namespace: data.namespace,
        releaseName: data.releaseName,
        source: data.source,
        description: data.description,
        replicas: data.replicas,
      };

      await request(`${environment.NX_COMPUTE_API_HOST}/compute/container`, {
        method: 'POST',
        body: JSON.stringify(body),
        mode: 'cors',
      });

      snackbar.showFeedback({
        message: 'Successfully Created Container',
        duration: 3500,
      });

      closeContainerDialog();
    } catch (error) {
      snackbar.showFeedback({
        message: 'Failed to create Container',
        duration: 3500,
        severity: 'error',
      });
      console.error(error);
    }
  };

  const vmDialogOpen = useState(false);
  const closeVmDialog = () => vmDialogOpen.set(false);

  const createVm = async (data: CreateVirtualMachineFields): Promise<void> => {
    try {
      const body: VirtualMachineRequest = {
        vmName: data.name,
        imageName: data.image,
        cpuCount: data.cpus,
        memMiB: data.ram,
        diskSpace: data.diskSpace as number,
        labels: data.labels,
      };

      await request(`${environment.NX_COMPUTE_API_HOST}/compute/vm`, {
        method: 'POST',
        body: JSON.stringify(body),
      });

      snackbar.showFeedback({
        message: 'Your VM has been submitted',
        duration: 3500,
      });
      closeVmDialog();
    } catch (error) {
      snackbar.showFeedback({
        message: 'Failed to create VM',
        duration: 3500,
        severity: 'error',
      });
      console.error(error);
    }
  };

  return (
    <>
      <PageHeader
        icon={<AccountTreeIcon />}
        title="Workloads"
        subtitle="Update 1 min ago"
      >
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => containerDialogOpen.set(true)}
        >
          <span className={classes.buttonTextFix}>Create Container</span>
        </Button>
        <div className={classes.spc}></div>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => vmDialogOpen.set(true)}
        >
          <span className={classes.buttonTextFix}>Create VM</span>
        </Button>
        {/* <div className={classes.spc}></div>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          endIcon={<OpenInNewIcon />}
        >
          <span className={classes.buttonTextFix}>Intersight</span>
        </Button> */}
      </PageHeader>

      <div className={classes.tabsContainer}>
        <Tabs value={value.get()} onChange={handleTabChange}>
          <Tab label="Virtual Machines" className={classes.tab} />
          <Tab label="Containers" className={classes.tab} />
        </Tabs>

        <TabPanel value={value.get()} index={0} className={classes.tabPanel}>
          <VmTable vmList={[...vmList.get()]} />
        </TabPanel>

        <TabPanel value={value.get()} index={1} className={classes.tabPanel}>
          <ContainerTable containerList={[...containerList.get()]} />
        </TabPanel>

        <Dialog
          open={containerDialogOpen.get()}
          onClose={closeContainerDialog}
          aria-labelledby="create-container-dialog"
        >
          <DialogContent>
            <CreateContainer
              onCancel={closeContainerDialog}
              onCreate={createContainer}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={vmDialogOpen.get()}
          onClose={closeVmDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <CreateVirtualMachine
              onCancel={closeVmDialog}
              onCreate={createVm}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Workloads;

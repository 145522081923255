import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PublicIcon from '@material-ui/icons/Public';

import { PageButton, PageTextBlock } from '@cisco-unified-cloud/shared/ui';

/* eslint-disable-next-line */
export interface NewsCardProps {}

const dt = new Date();

const articleList = [
  {
    id: 0,
    title:
      'Cisco Announces Intent To Acquire BabbleLabs To Improve Video Meeting Experience',
    date: new Date(),
    url: 'babblelabs link',
  },
  {
    id: 1,
    title: 'Cisco Webex Classrooms Unveiled For Education-Focused Partners',
    date: new Date(new Date().setHours(dt.getHours() - 15)),
    url: 'classrooms link',
  },
  {
    id: 2,
    title:
      'Cisco : Tools to Help You Deliver A Machine Learning Platform And Address Skill Gaps',
    date: new Date(new Date().setHours(dt.getHours() - 24)),
    url: 'machine learning platform link',
  },
  {
    id: 3,
    title:
      'HOW CONTENT PROTECTION MARKET WILL DOMINATE IN COMING YEARS? KEY PLAYERS: CISCO SYSTEMS, MICROSOFT, GOOGLE, ETC.',
    date: new Date(new Date().setHours(dt.getHours() - 26)),
    url: 'content protection link',
  },
];

export const NewsCard: FunctionComponent<NewsCardProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      avatar: {
        backgroundColor: theme.palette.primary.main,
      },
      list: {
        marginLeft: '-1rem',
      },
      listItemTextPrimary: {
        color: theme.palette.secondary.main,
      },
      listItemTextSecondary: {
        opacity: 0.8,
      },
      pageButton: {
        marginLeft: '3.5rem',
      },
    });
  });

  const classes = useStyles();

  return (
    <Box>
      <PageTextBlock title="Latest News" label="Updated 1 hour ago" size="md" />
      <List className={classes.list}>
        {articleList.map((article, index) => {
          return (
            <React.Fragment key={index}>
              <Link to={article.url}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <PublicIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span className={classes.listItemTextPrimary}>
                        {article.title}
                      </span>
                    }
                    secondary={
                      <span className={classes.listItemTextSecondary}>
                        {article.date.toString()}
                      </span>
                    }
                  />
                </ListItem>
              </Link>
            </React.Fragment>
          );
        })}
      </List>
      <div className={classes.pageButton}>
        <PageButton label="More News" url="/" />
      </div>
    </Box>
  );
};

export default NewsCard;

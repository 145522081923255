import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PolicyIcon from '@material-ui/icons/Policy';
import { PageHeader } from '@cisco-unified-cloud/shared/ui';
import { IamTable } from './iamTable';
import { useIamPage } from './useIamPage';

/* eslint-disable-next-line */
export interface IamProps {}

export const Iam: React.FC<IamProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      grid: {
        padding: '1rem',
      },
    });
  });

  const { data, loading, error } = useIamPage();

  const classes = useStyles();
  return (
    <>
      <PageHeader
        icon={<PolicyIcon />}
        title="Iam"
        subtitle="Update 1 min ago"
      ></PageHeader>
      <Grid container className={classes.grid}>
        <Grid item>
          <IamTable {...{ data, error, loading }} />
        </Grid>
      </Grid>
    </>
  );
};

export default Iam;

import React, { FunctionComponent } from 'react';
import { useState } from '@hookstate/core';
import {
  CircularProgress,
  TableSortLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import formatBytes from '../../util/formatBytes';

type Order = 'asc' | 'desc';

enum PowerStateEnum {
  Unknown,
  PoweredOn,
  PoweredOff,
  StandBy,
  Paused,
}

interface Column {
  'data-testid': string;
  id:
    | 'project'
    | 'diskCapacity'
    | 'memoryCapacity'
    | 'numCpus'
    | 'powerState'
    | 'tags'
    | 'vmName';
  label: string;
  minWidth?: number;
  align?: TableCellProps['align'];
  format?: (value: number) => string;
  sort: (a: VM, b: VM) => number;
}

const columns: Column[] = [
  {
    'data-testid': 'project',
    id: 'project',
    label: 'Project',
    minWidth: 120,
    sort: (a: VM, b: VM) => a.project.localeCompare(b.project),
  },
  {
    'data-testid': 'vmName',
    id: 'vmName',
    label: 'VM Name',
    minWidth: 120,
    sort: (a: VM, b: VM) => a.vmName.localeCompare(b.vmName),
  },
  {
    'data-testid': 'tags',
    id: 'tags',
    label: 'Tags',
    minWidth: 120,
    align: 'left',
    sort: (a: VM, b: VM) => a.tags.localeCompare(b.tags),
  },
  {
    'data-testid': 'powerState',
    id: 'powerState',
    label: 'Power State',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.powerState - b.powerState,
    format: (v) => PowerStateEnum[v] || 'Unknown',
  },
  {
    'data-testid': 'numCpus',
    id: 'numCpus',
    label: 'Num CPUs',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.numCpus - b.numCpus,
  },
  {
    'data-testid': 'memoryCapacity',
    id: 'memoryCapacity',
    label: 'Memory',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.memoryCapacity - b.memoryCapacity,
    format: (v) => formatBytes(v),
  },
  {
    'data-testid': 'diskCapacity',
    id: 'diskCapacity',
    label: 'Storage',
    minWidth: 80,
    align: 'right',
    sort: (a: VM, b: VM) => a.diskCapacity - b.diskCapacity,
    format: (v) => formatBytes(v),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: 440,
    height: '100%',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

interface VM {
  vmName: string;
  project?: string;
  powerState: number;
  numCpus: number;
  memoryCapacity: number;
  diskCapacity: number;
  tags: string;
}

export interface VmTableProps {
  vmList: VM[];
}

export const VmTable: FunctionComponent<VmTableProps> = ({ vmList }) => {
  const classes = useStyles();

  const page = useState(0);
  const rowsPerPage = useState(10);
  const order = useState<Order>('asc');
  const orderBy = useState<Column['id']>('vmName');
  const sortRoutine = columns.find((c) => c.id === orderBy.get())?.sort;
  if (!sortRoutine) {
    throw new Error('sortRoutine not found');
  }

  const handleRequestSort = (property: Column['id']) => {
    const isAsc = orderBy.get() === property && order.get() === 'asc';
    order.set(isAsc ? 'desc' : 'asc');
    orderBy.set(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    page.set(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    rowsPerPage.set(+event.target.value);
    page.set(0);
  };

  return (
    <Box className={classes.root}>
      {vmList.length && (
        <React.Fragment>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        data-testid={column['data-testid']}
                        active={orderBy.get() === column.id}
                        direction={
                          orderBy.get() === column.id ? order.get() : 'asc'
                        }
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                        {orderBy.get() === column.id ? (
                          <span className={classes.visuallyHidden}>
                            {order.get() === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {vmList
                  .slice(
                    page.get() * rowsPerPage.get(),
                    page.get() * rowsPerPage.get() + rowsPerPage.get()
                  )
                  .sort((a: VM, b: VM) =>
                    order.get() === 'asc'
                      ? sortRoutine(a, b)
                      : -sortRoutine(a, b)
                  )
                  .map((vm, i) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      onClick={async () => {
                        await navigate('/compute/vm-detail/', {
                          state: { vmname: vm.vmName },
                        });
                      }}
                    >
                      {columns.map((column, idx) => {
                        const value = vm[column.id];
                        const v =
                          column.format && typeof value === 'number'
                            ? column.format(value)
                            : value;
                        return (
                          <TableCell
                            data-testid={`cell-${column.id}-${i}`}
                            key={`${column.id}-${i}-${idx}`}
                            align={column.align}
                          >
                            {v}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="span"
            count={vmList.length}
            rowsPerPage={rowsPerPage.get()}
            page={page.get()}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </React.Fragment>
      )}
      {!vmList.length && <CircularProgress />}
    </Box>
  );
};

export default VmTable;

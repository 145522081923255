import React from 'react';
import { useState } from '@hookstate/core';
import { useTheme } from '@material-ui/core/styles';
import { Button, Grid, Tab, Tabs } from '@material-ui/core';
import {
  Apps as AppsIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@material-ui/icons';

import { PageHeader, TabPanel } from '@cisco-unified-cloud/shared/ui';
import { staticData, staticFavoriteData } from './projects-mock-data';
import ProjectCard from './cards/project-card/project-card';
import useStyles from './projects.styles';

export interface ProjectsProps {
  data?: {
    name: string;
    id: string;
    capacity: string;
    errors: string;
    utilization: string;
    storage: string;
    vms: string;
  }[];
}

export const Projects: React.FC<ProjectsProps> = ({ data }) => {
  const theme = useTheme();

  const classes = useStyles(theme);

  const value = useState(0);

  const handleTabChange = (_: unknown, newValue: number) => {
    value.set(newValue);
  };

  return (
    <>
      <PageHeader
        icon={<AppsIcon />}
        title="Projects"
        subtitle="Update 1 min ago"
      >
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          endIcon={<AddCircleOutlineIcon />}
        >
          <span className={classes.buttonTextFix}>Create Project</span>
        </Button>
      </PageHeader>

      <div className={classes.tabsContainer}>
        <Tabs value={value.get()} onChange={handleTabChange}>
          <Tab label="All Projects" className={classes.tab} />
          <Tab label="Favorites" className={classes.tab} />
        </Tabs>
        <TabPanel value={value.get()} index={0} className={classes.tabPanel}>
          <Grid container className={classes.grid} spacing={4}>
            {staticData.map((o, k) => (
              <Grid item key={k}>
                <ProjectCard
                  name={o.name}
                  id={o.id}
                  capacity={o.capacity}
                  errors={o.errors}
                  storage={o.storage}
                  vms={o.vms}
                  utilization={o.utilization}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={value.get()} index={1} className={classes.tabPanel}>
          <Grid container className={classes.grid} spacing={4}>
            {staticFavoriteData.map((o, k) => (
              <Grid item key={k}>
                <ProjectCard
                  name={o.name}
                  id={o.id}
                  capacity={o.capacity}
                  errors={o.errors}
                  storage={o.storage}
                  vms={o.vms}
                  utilization={o.utilization}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </div>
    </>
  );
};

export default Projects;

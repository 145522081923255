interface IAM {
  member: string;
  name: string;
  roles: string[];
}

export const iamTableMockData: IAM[] = [
  {
    member: "application@acme.athenacloud.com",
    name: "application",
    roles: ["Trace Agent", "Logs Writer"],
  },
  {
    member: "john@acme.com",
    name: "John Hamilton",
    roles: ["Editor"],
  },
  {
    member: "melody@acme.com",
    name: "Melody Springer",
    roles: ["Owner"],
  },
  {
    member: "dwayne@acme.com",
    name: "Dwayne Hicks",
    roles: ["Editor"],
  },
  {
    member: "service-x73@acme.athenacloud.com",
    name: "vm-service",
    roles: ["Compute Agent", "Logs Writer", "Error Writer"],
  },
  {
    member: "service-9h4@acme.athenacloud.com",
    name: "trace-service",
    roles: ["Trace Agent", "Logs Writer", "Error Writer"],
  },
  {
    member: "bill@acme.com",
    name: "William Hudson",
    roles: ["Viewer"],
  },
];

import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { PageButton, PageTextBlock } from '@cisco-unified-cloud/shared/ui';

/* eslint-disable-next-line */
export interface BillingSummaryCardProps {}

export const BillingSummaryCard: FunctionComponent<BillingSummaryCardProps> = (
  props
) => {
  return (
    <Box>
      <PageTextBlock
        title="This Month"
        label="September 1, 2020 - September 30, 2020"
        size="md"
      />
      <br />
      <Typography variant="h5" noWrap>
        <strong>$987.00</strong>
      </Typography>
      Month-to-date total cost
      <br />
      <br />
      <Button
        style={{ display: 'flex', alignItems: 'center' }}
        variant="contained"
        color="primary"
        href="https://ignw-test.chargebee.com/d/invoices/65"
        target="_blank"
      >
        <CreditCardIcon />
        &nbsp;&nbsp;&nbsp;
        <Typography variant="subtitle1" color="secondary" noWrap>
          Pay Bill
        </Typography>
      </Button>
      <br />
      <br />
      <PageButton label="Understand Your Costs" url="/" />
      <br />
      <PageButton label="Get Support" url="/" />
    </Box>
  );
};

export default BillingSummaryCard;

import { keycloak } from '../keycloak';

type GenericObject = {
  [key: string]: any;
};

export const getToken = () =>
  typeof window !== 'undefined' ? keycloak.token : '';

export const getHeaders = () => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${getToken()}`);
  headers.append('Content-Type', 'application/json; charset=utf-8');
  return headers;
};

export const request = async <T = GenericObject>(
  url: string,
  init?: RequestInit
): Promise<T> => {
  const headers = getHeaders();

  const res = await fetch(url, { mode: 'cors', headers, ...init }).catch(
    (err) => {
      console.log('request: network error making request', err);
      throw err;
    }
  );
  const text = await res.text();
  if (res.status >= 300) {
    throw new Error(`${res.status} ${text}`);
  } else {
    const json = text === '' ? {} : JSON.parse(text);
    return json;
  }
};

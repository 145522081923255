import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { CostTrendChart } from '@cisco-unified-cloud/shared/ui';
import { PageTextBlock } from '@cisco-unified-cloud/shared/ui';

/* eslint-disable-next-line */
export interface WorkloadsDeployedCardProps {}

export const WorkloadsDeployedCard: FunctionComponent<WorkloadsDeployedCardProps> = (
  props
) => {
  return (
    <Box>
      <PageTextBlock
        title="Workloads Deployed"
        label="September 1, 2020 - September 30, 2020"
        size="md"
      />
      <div style={{ width: '20rem', height: '16rem' }}>
        <CostTrendChart />
      </div>
    </Box>
  );
};

export default WorkloadsDeployedCard;

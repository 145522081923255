import React, { FunctionComponent } from 'react';

import { useKeycloak } from '@react-keycloak/web';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
  BrowserRouter as Router,
  Switch as RouterSwitch,
  Route,
  Link as RouterLink,
  withRouter,
} from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AppEmptyLayout } from '@cisco-unified-cloud/shared/ui';
import { AppLayout } from '@cisco-unified-cloud/shared/ui';
import {
  Billing,
  Cloud,
  Dashboard,
  Iam,
  Login,
  Networking,
  Notifications,
  Projects,
  Storage,
  Workloads,
} from '@cisco-unified-cloud/client-ui';

import { icons, menu } from './app-menu';

/* eslint-disable-next-line */
export interface AppProps {}

export interface AppLayoutProps {
  icons?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    url?: string;
  }[];
  menu?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    url?: string;
  }[];
  title?: string;
}
export const App: FunctionComponent<AppLayoutProps> = (props) => {
  const { keycloak, initialized } = useKeycloak();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      page: {
        paddingTop: '4rem',
      },
      grid: {
        height: '100vh',
      },
    });
  });

  const classes = useStyles();

  if (!initialized)
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <CircularProgress />
      </Grid>
    );

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  const appLayoutProps = {
    icons: icons,
    menu: menu,
    title: 'Project Name',
  };

  return (
    <Router>
      <RouterSwitch>
        <Route path="/billing">
          <AppLayout {...appLayoutProps}>
            <Billing />
          </AppLayout>
        </Route>

        <Route path="/dashboard">
          <AppLayout {...appLayoutProps}>
            <Dashboard />
          </AppLayout>
        </Route>

        <Route path="/iam">
          <AppLayout {...appLayoutProps}>
            <Iam />
          </AppLayout>
        </Route>

        <Route path="/cloud">
          <AppLayout {...appLayoutProps}>
            <Cloud />
          </AppLayout>
        </Route>

        <Route path="/workloads">
          <AppLayout {...appLayoutProps}>
            <Workloads />
          </AppLayout>
        </Route>

        <Route path="/storage">
          <AppLayout {...appLayoutProps}>
            <Storage />
          </AppLayout>
        </Route>

        <Route path="/networking">
          <AppLayout {...appLayoutProps}>
            <Networking />
          </AppLayout>
        </Route>

        <Route path="/notifications">
          <AppLayout {...appLayoutProps}>
            <Notifications />
          </AppLayout>
        </Route>

        <Route path="/projects">
          <AppLayout {...appLayoutProps}>
            <Projects />
          </AppLayout>
        </Route>

        <Route path="/login">
          <AppEmptyLayout>
            <Login />
          </AppEmptyLayout>
        </Route>

        <Route path="/">
          <AppEmptyLayout>
            <Login />
          </AppEmptyLayout>
        </Route>
      </RouterSwitch>
    </Router>
  );
};

export default App;

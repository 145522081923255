interface Container {
  id: number;
  name: string;
  namespace: string;
  status: string;
  restarts: string;
  uptime: string;
}

export const containerTableMockData: Container[] = [
  {
    id: 4756887,
    name: 'ccp-helm-operator-6f4d9d9f4c-lv876',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:20:41',
  },
  {
    id: 4756888,
    name: 'ccp-monitor-grafana-855945976c-glp64',
    namespace: 'ccp',
    status: 'Running',
    restarts: '0',
    uptime: '21 days, 1:04:48',
  },
  {
    id: 4756889,
    name: 'ccp-monitor-grafana-set-datasource-f4pz7',
    namespace: 'ccp',
    status: 'Succeeded',
    restarts: '0',
    uptime: '163 days, 5:12:36',
  },
  {
    id: 4756890,
    name: 'ccp-monitor-prometheus-alertmanager-7d648474bd-stdkf',
    namespace: 'ccp',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:12:36',
  },
  {
    id: 4756891,
    name: 'ccp-monitor-prometheus-kube-state-metrics-7f7c9f986-xpjcp',
    namespace: 'ccp',
    status: 'Running',
    restarts: '4',
    uptime: '163 days, 5:12:36',
  },
  {
    id: 4756892,
    name: 'ccp-monitor-prometheus-node-exporter-6g9mg',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:12:38',
  },
  {
    id: 4756893,
    name: 'ccp-monitor-prometheus-node-exporter-bbt2w',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '110 days, 2:51:33',
  },
  {
    id: 4756894,
    name: 'ccp-monitor-prometheus-node-exporter-grrt2',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:12:38',
  },
  {
    id: 4756895,
    name: 'ccp-monitor-prometheus-node-exporter-hqkjk',
    namespace: 'ccp',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:12:38',
  },
  {
    id: 4756896,
    name: 'ccp-monitor-prometheus-pass-job-vo8zm-f44bh',
    namespace: 'ccp',
    status: 'Succeeded',
    restarts: '0',
    uptime: '21 days, 1:07:16',
  },
  {
    id: 4756897,
    name: 'ccp-monitor-prometheus-port-update-bp7wc-wnwn9',
    namespace: 'ccp',
    status: 'Succeeded',
    restarts: '0',
    uptime: '21 days, 1:07:16',
  },
  {
    id: 4756898,
    name: 'ccp-monitor-prometheus-pushgateway-54ddd4865-ggsxb',
    namespace: 'ccp',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:12:37',
  },
  {
    id: 4756899,
    name: 'ccp-monitor-prometheus-server-5699bbf88-zd9xj',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:12:37',
  },
  {
    id: 4756900,
    name: 'ccp-vip-manager-athena-611-master-gro-a76ae1c113',
    namespace: 'ccp',
    status: 'Running',
    restarts: '6',
    uptime: '163 days, 5:23:25',
  },
  {
    id: 4756901,
    name: 'cert-manager-7c4fdf69b7-8ln5k',
    namespace: 'ccp',
    status: 'Running',
    restarts: '5',
    uptime: '163 days, 5:23:38',
  },
  {
    id: 4756902,
    name: 'kubernetes-dashboard-dbfcd4d-gxhgs',
    namespace: 'ccp',
    status: 'Running',
    restarts: '4',
    uptime: '163 days, 5:13:20',
  },
  {
    id: 4756903,
    name: 'metallb-controller-6c8c5fd7fd-6l5qb',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:23:53',
  },
  {
    id: 4756904,
    name: 'metallb-speaker-5dbtw',
    namespace: 'ccp',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:18:24',
  },
  {
    id: 4756905,
    name: 'metallb-speaker-bkfpj',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '110 days, 2:51:33',
  },
  {
    id: 4756906,
    name: 'metallb-speaker-g7bd5',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:15:33',
  },
  {
    id: 4756907,
    name: 'metallb-speaker-ztqv8',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:21:01',
  },
  {
    id: 4756908,
    name: 'nginx-ingress-controller-784gd',
    namespace: 'ccp',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:18:24',
  },
  {
    id: 4756909,
    name: 'nginx-ingress-controller-f7m5b',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '110 days, 2:51:33',
  },
  {
    id: 4756910,
    name: 'nginx-ingress-controller-nxl4r',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:21:02',
  },
  {
    id: 4756911,
    name: 'nginx-ingress-controller-sd2h5',
    namespace: 'ccp',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:15:34',
  },
  {
    id: 4756912,
    name: 'nginx-ingress-default-backend-8465968b95-nqwwj',
    namespace: 'ccp',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:23:51',
  },
  {
    id: 4756913,
    name: 'andy-2-jk29-mongodb-67c885bf78-4gcj5',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:34',
  },
  {
    id: 4756914,
    name: 'andy-mongo-0s0r-mongodb-f89d66ff-xcgrt',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:51',
  },
  {
    id: 4756915,
    name: 'andy-mongo-jj3r-mongodb-78956d95f7-vr5wl',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:38',
  },
  {
    id: 4756916,
    name: 'andys123-i2l9-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:58',
  },
  {
    id: 4756917,
    name: 'chris2-z78k-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:22',
  },
  {
    id: 4756918,
    name: 'contdemo01-q1cp-mongodb-675c5f9b48-n882z',
    namespace: 'default',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:27:12',
  },
  {
    id: 4756919,
    name: 'demo-tues-12-wx0d-mongodb-567648c665-spbqz',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:48',
  },
  {
    id: 4756920,
    name: 'hrms-jan21-drwt-mongodb-559b488975-s266m',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:04',
  },
  {
    id: 4756921,
    name: 'hrms-mongo-k0ua-mongodb-54d5b6f8dc-2k5qd',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '10 days, 4:16:21',
  },
  {
    id: 4756922,
    name: 'minio-q2bi-85f7cbd678-67hv2',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:32',
  },
  {
    id: 4756923,
    name: 'mon21-redis-1-master-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:11',
  },
  {
    id: 4756924,
    name: 'mon21-redis-1-slave-0',
    namespace: 'default',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:27:11',
  },
  {
    id: 4756925,
    name: 'mon21-redis-1-slave-1',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:30',
  },
  {
    id: 4756926,
    name: 'mongo-09-rd6n-mongodb-c9954c57c-268n9',
    namespace: 'default',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:26:36',
  },
  {
    id: 4756927,
    name: 'mycontth4-s14a-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:41',
  },
  {
    id: 4756928,
    name: 'mynew-redis-anaa-master-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:35',
  },
  {
    id: 4756929,
    name: 'mynew-redis-anaa-slave-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:35',
  },
  {
    id: 4756930,
    name: 'mynew-redis-anaa-slave-1',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:57',
  },
  {
    id: 4756931,
    name: 'nov18-svqw-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:26:30',
  },
  {
    id: 4756932,
    name: 'phil-demo-mongo01072021-vu12-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:34',
  },
  {
    id: 4756933,
    name: 'phil-demo-mongo2-sblg-mongodb-84577d7f6c-fsgfc',
    namespace: 'default',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:27:38',
  },
  {
    id: 4756934,
    name: 'phil-demo1-86ws-mongodb-6756f67cbf-qj8fw',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:37',
  },
  {
    id: 4756935,
    name: 'phil-mongo-db-demo3-mongodb-58799dbdc8-6cvq5',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:40',
  },
  {
    id: 4756936,
    name: 'phil-rabbitmq-01072021-p1nm-0',
    namespace: 'default',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:27:16',
  },
  {
    id: 4756937,
    name: 'rabbit-4pm-mk69-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:48',
  },
  {
    id: 4756938,
    name: 'rabbit-demo-mzw8-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:21',
  },
  {
    id: 4756939,
    name: 'rabbit-jf4s-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:18',
  },
  {
    id: 4756940,
    name: 'rabbit01-rva4-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:46',
  },
  {
    id: 4756941,
    name: 'troy-mongo-test-mongodb-5cff59c4f6-6cgrf',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:27:36',
  },
  {
    id: 4756942,
    name: 'tues22-mongo-3-mongodb-6dd9ffc6bd-l9bt8',
    namespace: 'default',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:28',
  },
  {
    id: 4756943,
    name: 'webf3-fge4-rabbitmq-0',
    namespace: 'default',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:27:38',
  },
  {
    id: 4756944,
    name: 'calico-kube-controllers-7b65b8c44d-vgwkb',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:23:46',
  },
  {
    id: 4756945,
    name: 'calico-node-2cnjp',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:15:49',
  },
  {
    id: 4756946,
    name: 'calico-node-5hqgj',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '110 days, 2:51:50',
  },
  {
    id: 4756947,
    name: 'calico-node-jzfsz',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:18:40',
  },
  {
    id: 4756948,
    name: 'calico-node-qrdc7',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:23:47',
  },
  {
    id: 4756949,
    name: 'calico-node-zgdt7',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:21:18',
  },
  {
    id: 4756950,
    name: 'coredns-65f5bff896-67687',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:24:18',
  },
  {
    id: 4756951,
    name: 'coredns-65f5bff896-glb5c',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:24:17',
  },
  {
    id: 4756952,
    name: 'etcd-athena-611-master-gro-a76ae1c113',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '4',
    uptime: '163 days, 5:23:37',
  },
  {
    id: 4756953,
    name: 'kube-apiserver-athena-611-master-gro-a76ae1c113',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '4',
    uptime: '163 days, 5:23:35',
  },
  {
    id: 4756954,
    name: 'kube-controller-manager-athena-611-master-gro-a76ae1c113',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '11',
    uptime: '163 days, 5:23:22',
  },
  {
    id: 4756955,
    name: 'kube-proxy-k7rtt',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:21:18',
  },
  {
    id: 4756956,
    name: 'kube-proxy-s6jc4',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:15:49',
  },
  {
    id: 4756957,
    name: 'kube-proxy-sxq88',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:24:18',
  },
  {
    id: 4756958,
    name: 'kube-proxy-z2n8d',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:18:40',
  },
  {
    id: 4756959,
    name: 'kube-proxy-zhv65',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '110 days, 2:51:50',
  },
  {
    id: 4756960,
    name: 'kube-scheduler-athena-611-master-gro-a76ae1c113',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '11',
    uptime: '163 days, 5:23:26',
  },
  {
    id: 4756961,
    name: 'nvidia-device-plugin-daemonset-cfqjn',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:15:37',
  },
  {
    id: 4756962,
    name: 'nvidia-device-plugin-daemonset-dktbk',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '110 days, 2:51:37',
  },
  {
    id: 4756963,
    name: 'nvidia-device-plugin-daemonset-g4lp6',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '2',
    uptime: '163 days, 5:21:05',
  },
  {
    id: 4756964,
    name: 'nvidia-device-plugin-daemonset-qwgrj',
    namespace: 'kube-system',
    status: 'Running',
    restarts: '3',
    uptime: '163 days, 5:18:28',
  },
  {
    id: 4756965,
    name: 'redis-alpha-master-0',
    namespace: 'redis',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:20',
  },
  {
    id: 4756966,
    name: 'redis-alpha-slave-0',
    namespace: 'redis',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:20',
  },
  {
    id: 4756967,
    name: 'redis-alpha-slave-1',
    namespace: 'redis',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:25:30',
  },
  {
    id: 4756968,
    name: 'redis-beta-master-0',
    namespace: 'redis',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:26:14',
  },
  {
    id: 4756969,
    name: 'redis-beta-slave-0',
    namespace: 'redis',
    status: 'Running',
    restarts: '1',
    uptime: '13 days, 1:26:14',
  },
  {
    id: 4756970,
    name: 'redis-beta-slave-1',
    namespace: 'redis',
    status: 'Running',
    restarts: '0',
    uptime: '13 days, 1:25:29',
  },
];

import React, { FunctionComponent } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';

import { PageHeader, PageTextBlock } from '@cisco-unified-cloud/shared/ui';
import { AverageUtilizationCard } from './cards/average-utilization-card/average-utilization-card';
import { CostTrendCard } from './cards/cost-trend-card/cost-trend-card';
import { DocumentationCard } from './cards/documentation-card/documentation-card';
import { NewsCard } from './cards/news-card/news-card';
import { ProjectCostCard } from './cards/project-cost-card/project-cost-card';
import { ProjectedCapacityCard } from './cards/projected-capacity-card/projected-capacity-card';
import { ProjectSummaryCard } from './cards/project-summary-card/project-summary-card';
import { WorkloadsDeployedCard } from './cards/workloads-deployed-card/workloads-deployed-card';

/* eslint-disable-next-line */
export interface DashboardProps {}

export const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      card: {
        width: '33%',
        height: '24rem',
        padding: '1rem',
        marginBottom: '1rem',
      },
      bottomCard: {
        width: '50%',
        height: '24rem',
        padding: '1rem',
      },
      bottomSpace: {
        height: '16rem',
      },
      grid: {
        padding: '1rem',
        height: 'calc(100vh - 8.5rem)',
        overflow: 'auto',
      },
    });
  });

  const classes = useStyles();

  return (
    <>
      <PageHeader
        icon={<DashboardIcon />}
        title="Dashboard"
        subtitle="Update 1 min ago"
      >
        <Button color="secondary" variant="outlined">
          Customize
        </Button>
      </PageHeader>

      <Grid container className={classes.grid}>
        <Grid item className={classes.card}>
          <ProjectSummaryCard />
        </Grid>
        <Grid item className={classes.card}>
          <CostTrendCard />
        </Grid>
        <Grid item className={classes.card}>
          <ProjectCostCard />
        </Grid>
        <Grid item className={classes.card}>
          <ProjectedCapacityCard />
        </Grid>
        <Grid item className={classes.card}>
          <AverageUtilizationCard />
        </Grid>
        <Grid item className={classes.card}>
          <WorkloadsDeployedCard />
        </Grid>
        <Grid item className={classes.bottomCard}>
          <NewsCard />
        </Grid>
        <Grid item className={classes.bottomCard}>
          <DocumentationCard />
        </Grid>
        <Grid item className={classes.bottomSpace}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;

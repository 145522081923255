import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    buttonTextFix: {
      marginTop: '.15rem',
    },
    dataRows: {
      paddingBottom: '1rem',
    },
    dataRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    label: {
      color: theme.palette.primary.main,
    },
    value: {
      color: theme.palette.primary.contrastText,
    },
    grid: {
      padding: '2rem',
    },
    tab: {
      padding: '0 1rem',
    },
    tabsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tabPanel: {},
  });
});

export default useStyles;

import { useContext, useEffect } from 'react';
import { useState } from '@hookstate/core';
import { IamTableProps } from './iamTable';
import { SnackBarContext } from '../../providers/SnackbarProvider';
import { request } from '../../util/request';
import { environment } from '@cisco-unified-cloud/shared/client-env';

export const USER_LIST_API = `${environment.NX_IAM_API_URL}/api/users`;

export interface UseIamPageResponse {
  data: IamTableProps['data'];
  error: Error['message'] | null;
  loading: boolean;
}

export interface UserListResponse {
  response: IamTableProps['data'];
}

export const useIamPage = (): UseIamPageResponse => {
  const data = useState<IamTableProps['data']>([]);
  const error = useState<Error['message'] | null>(null);
  const loading = useState<boolean>(true);
  const snackbar = useContext(SnackBarContext);

  useEffect(() => {
    const handleError = (err: Error) => {
      error.set(err.message);
      snackbar.showFeedback({
        severity: 'error',
        message: err.message,
      });
    };
    const getUserList = async (): Promise<IamTableProps['data']> => {
      try {
        const dat = await request<UserListResponse>(USER_LIST_API);
        console.log(dat.response);
        return dat.response;
      } catch (err) {
        handleError(err);
        return [];
      }
    };
    (async () => {
      const users = await getUserList();
      data.set(users);
      loading.set(false);
      error.set(null);
    })();
  }, [snackbar]);

  return {
    data: [...data.get()],
    error: error.get(),
    loading: loading.get(),
  };
};

import React, { FunctionComponent } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import MemoryIcon from '@material-ui/icons/Memory';
import SdCardIcon from '@material-ui/icons/SdCard';
import StorageIcon from '@material-ui/icons/Storage';

import { PageButton, PageTextBlock } from '@cisco-unified-cloud/shared/ui';

/* eslint-disable-next-line */
export interface ProjectSummaryCardProps {}

export const ProjectSummaryCard: FunctionComponent<ProjectSummaryCardProps> = (
  props
) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      avatar: {
        background: 'none',
        color: theme.palette.secondary.main,
      },
      dataRow: {
        display: 'flex',
        alignItems: 'flex-end',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        minWidth: '20rem',
        maxWidth: '22rem',
      },
      dataPoint: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid ${theme.palette.primary.main}`,
        padding: '.5rem 0 1rem 0',
      },
      dataValue: {
        color: theme.palette.text.primary,
      },
      dataLabel: {
        color: theme.palette.primary.main,
      },
      actionItems: {
        display: 'flex',
        justifyContent: 'space-around',
      },
    });
  });

  const classes = useStyles();
  return (
    <Box>
      <PageTextBlock title="Project Summary" label="Project Name" size="md" />
      <PageTextBlock label="Project ID 12345678910" size="md" />
      <br />
      <div className={classes.dataRow}>
        <div className={classes.dataPoint}>
          <Avatar className={classes.avatar}>
            <MemoryIcon />
          </Avatar>
          <Typography variant="h5" noWrap className={classes.dataValue}>
            <strong>12%</strong>
          </Typography>
          <span className={classes.dataLabel}>CPU</span>
        </div>
        <div className={classes.dataPoint}>
          <Avatar className={classes.avatar}>
            <SdCardIcon />
          </Avatar>
          <Typography variant="h5" noWrap className={classes.dataValue}>
            <strong>640k</strong>
          </Typography>
          <span className={classes.dataLabel}>Memory</span>
        </div>
        <div className={classes.dataPoint}>
          <Avatar className={classes.avatar}>
            <StorageIcon />
          </Avatar>
          <Typography variant="h5" noWrap className={classes.dataValue}>
            <strong>4pb</strong>
          </Typography>
          <span className={classes.dataLabel}>Storage</span>
        </div>
      </div>
      <br />
      <div className={classes.actionItems}>
        <PageButton label="Edit Project" url="/" />
        <PageButton
          label="Pay My Bill"
          url="https://ignw-test.chargebee.com/d/invoices/65"
          external
        />
      </div>
    </Box>
  );
};

export default ProjectSummaryCard;

import React from 'react';
import { useState } from '@hookstate/core';
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TablePagination,
  TableSortLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Alerts } from '@cisco-unified-cloud/shared/ui';

import { UseIamPageResponse } from './useIamPage';

type Order = 'asc' | 'desc';

interface Column {
  id: 'checkbox' | 'member' | 'menu' | 'name' | 'roles';
  label: string;
  minWidth?: number;
  align?: 'left';
  format?: (value: number) => string;
  sortable: boolean;
  sort: (a: IAM, b: IAM) => number;
}

const columns: Column[] = [
  {
    id: 'checkbox',
    label: '',
    sortable: false,
    sort: () => 1,
  },
  {
    id: 'member',
    label: 'Member',
    minWidth: 170,
    sortable: true,
    sort: (a: IAM, b: IAM) => a.member.localeCompare(b.member),
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 80,
    sortable: true,
    sort: (a: IAM, b: IAM) => a.name.localeCompare(b.name),
  },
  {
    id: 'roles',
    label: 'Role(s)',
    minWidth: 170,
    sortable: true,
    sort: (a: IAM, b: IAM) => {
      const aStr = a.roles.sort((s1, s2) => s1.localeCompare(s2)).join();
      const bStr = b.roles.sort((s1, s2) => s1.localeCompare(s2)).join();
      return aStr.localeCompare(bStr);
    },
  },
  {
    id: 'menu',
    label: '',
    sortable: false,
    sort: () => 1,
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkbox: {
    '&$checked': {
      color: '#00bceb',
    },
  },
  checked: {},
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
});

export interface IAM {
  member: string;
  name: string;
  roles: string[];
}

export interface IamTableProps extends UseIamPageResponse {
  data: IAM[];
}

export const IamTable: React.FC<IamTableProps> = ({ data, loading, error }) => {
  const classes = useStyles();

  const page = useState(0);
  const rowsPerPage = useState(10);
  const order = useState<Order>('asc');
  const orderBy = useState<Column['id']>('member');
  const selected = useState<string[]>([]);

  const sortRoutine = columns.find((c) => c.id === orderBy.get())?.sort;

  const isSelected = (member: string) => selected.get().indexOf(member) !== -1;

  if (!sortRoutine) {
    throw new Error('sortRoutine not found');
  }

  const handleRequestSort = (property: Column['id']) => {
    const isAsc = orderBy.get() === property && order.get() === 'asc';
    order.set(isAsc ? 'desc' : 'asc');
    orderBy.set(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    page.set(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    rowsPerPage.set(+event.target.value);
    page.set(0);
  };

  const handleCheckboxClick = (name: string) => {
    const selectedIndex = selected.get().indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected.get(), name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.get().slice(1));
    } else if (selectedIndex === selected.get().length - 1) {
      newSelected = newSelected.concat(selected.get().slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.get().slice(0, selectedIndex),
        selected.get().slice(selectedIndex + 1)
      );
    }

    selected.set(newSelected);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="IAM table">
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={`${column.id}-${idx}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy.get() === column.id}
                      direction={
                        orderBy.get() === column.id ? order.get() : 'asc'
                      }
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                      {orderBy.get() === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order.get() === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 &&
              data
                .sort((a: IAM, b: IAM) =>
                  order.get() === 'asc' ? sortRoutine(a, b) : -sortRoutine(a, b)
                )
                .map((row, idx) => {
                  const isItemSelected = isSelected(row.member);
                  return (
                    <TableRow key={`${row.member}-${idx}`}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': '' }}
                          onClick={() => handleCheckboxClick(row.member)}
                          classes={{
                            root: classes.checkbox,
                            checked: classes.checked,
                          }}
                        />
                      </TableCell>
                      <TableCell scope="row">{row.member}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.roles.join(', ')}</TableCell>
                      <TableCell align="right">
                        <IconButton aria-label="actions">
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage.get()}
        page={page.get()}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      {error && <Alerts severity="error" message={error} isOpen={true} />}
      {loading && (
        <Grid item xs={12} className={classes.loading}>
          <CircularProgress />
        </Grid>
      )}
    </Paper>
  );
};

export default IamTable;

import React, { FunctionComponent } from 'react';
import { useState } from '@hookstate/core';
import {
  CircularProgress,
  TableSortLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Order = 'asc' | 'desc';

interface Column {
  'data-testid': string;
  id: 'name' | 'namespace' | 'status' | 'restarts' | 'uptime';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
  sort: (a: Container, b: Container) => number;
}

const columns: Column[] = [
  {
    'data-testid': 'name',
    id: 'name',
    label: 'Container Name',
    minWidth: 170,
    sort: (a: Container, b: Container) => a.name.localeCompare(b.name),
  },
  {
    'data-testid': 'namespace',
    id: 'namespace',
    label: 'Namespace / Project',
    minWidth: 80,
    align: 'right',
    sort: (a: Container, b: Container) =>
      a.namespace.localeCompare(b.namespace),
  },
  {
    'data-testid': 'status',
    id: 'status',
    label: 'Status',
    minWidth: 80,
    align: 'right',
    sort: (a: Container, b: Container) => a.status.localeCompare(b.status),
  },
  {
    'data-testid': 'restarts',
    id: 'restarts',
    label: 'Restarts',
    minWidth: 80,
    align: 'right',
    sort: (a: Container, b: Container) =>
      parseInt(a.restarts) - parseInt(b.restarts),
  },
  {
    'data-testid': 'uptime',
    id: 'uptime',
    label: 'Uptime',
    minWidth: 80,
    align: 'right',
    sort: (a: Container, b: Container) => a.status.localeCompare(b.status),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: 440,
    height: '100%',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

interface Container {
  id: string;
  name: string;
  namespace: string;
  status: string;
  restarts: string;
  uptime: string;
}

export interface ContainerTableProps {
  containerList: Container[];
}

export const ContainerTable: FunctionComponent<ContainerTableProps> = ({
  containerList,
}) => {
  const classes = useStyles();
  const page = useState(0);
  const rowsPerPage = useState(10);
  const order = useState<Order>('asc');
  const orderBy = useState<Column['id']>('name');

  const sortRoutine = columns.find((c) => c.id === orderBy.get())?.sort;
  if (!sortRoutine) {
    throw new Error('sortRoutine not found');
  }

  const handleRequestSort = (property: Column['id']) => {
    const isAsc = orderBy.get() === property && order.get() === 'asc';
    order.set(isAsc ? 'desc' : 'asc');
    orderBy.set(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    page.set(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    rowsPerPage.set(+event.target.value);
    page.set(0);
  };

  return (
    <Box className={classes.root}>
      {containerList.length && (
        <React.Fragment>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        data-testid={column['data-testid']}
                        active={orderBy.get() === column.id}
                        direction={
                          orderBy.get() === column.id ? order.get() : 'asc'
                        }
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                        {orderBy.get() === column.id ? (
                          <span className={classes.visuallyHidden}>
                            {order.get() === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {containerList
                  .slice(
                    page.get() * rowsPerPage.get(),
                    page.get() * rowsPerPage.get() + rowsPerPage.get()
                  )
                  .sort((a: Container, b: Container) =>
                    order.get() === 'asc'
                      ? sortRoutine(a, b)
                      : -sortRoutine(a, b)
                  )
                  .map((container, i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map((column, idx) => {
                        const value = container[column.id];
                        const v =
                          column.format && typeof value === 'number'
                            ? column.format(value)
                            : value;
                        return (
                          <TableCell
                            data-testid={`cell-${column.id}-${i}`}
                            key={`${column.id}-${i}-${idx}`}
                            align={column.align}
                          >
                            {v}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={containerList.length}
            rowsPerPage={rowsPerPage.get()}
            page={page.get()}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </React.Fragment>
      )}
      {!containerList.length && <CircularProgress />}
    </Box>
  );
};

export default ContainerTable;
